import { defineStore } from "pinia";
interface ConfigDetails {
 country_code: string;
  label: string;
  id: number;
  has_disabled_incoming_call: boolean;
  phone_number: string;
  plan: number;
  reclaim_date: string | null;
  is_retained: boolean;
  date_created: string;
}
interface TextToSpeechAddOnState {
        showSelectTextToSpeechNumberModal: boolean;
        showSendTestTextToSpeechModal: boolean;
        showChangeTextToSpeechNumberModal: boolean;
        configDetails: ConfigDetails | null;
  }


export const useTextToSpeechAddOnStore = defineStore({
    id: "text-to-speech-addon",
    state: (): TextToSpeechAddOnState => ({
        showSelectTextToSpeechNumberModal: false,
        showSendTestTextToSpeechModal: false,
        showChangeTextToSpeechNumberModal: false,
        configDetails: null,
  }),

});