import { defineStore } from "pinia";
import { PINIA_PERSIST_OPTIONS } from "../helpers";
import { QueueItemType } from "../helpers/types/callQueue";

interface QueueState {
  activeContact: QueueItemType | null;
}

export const useQueueStore = defineStore({
  id: "queue",
  state: (): QueueState => ({
    activeContact: null, //current active call in call Queue
  }),
  getters: {},
  actions: {},
  persist: PINIA_PERSIST_OPTIONS,
});
