import logger from "./logger"
import { fetchCallConfig } from "./queries/call-config"

export const getCallFlows = async(link?: string) => {
    try {
      const response = await fetchCallConfig(link)
      return response
    } catch (error) {
      logger.error(error, 'Get all call configuration')
    }
  }