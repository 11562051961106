import { defineStore } from "pinia";
interface ConfigDetails {
 country_code: string;
  label: string;
  id: number;
  phone_number: string;
  plan: number;
  reclaim_date: string | null;
  is_retained: boolean;
  date_created: string;
}
interface VoiceOtpAddOnState {
      showSelectVoiceOtpNumberModal: boolean;
      showChangeVoiceOtpNumberModal: boolean;
      showSendTestOtpModal: boolean;
      configDetails: ConfigDetails | null;
  }


export const useVoiceOtpAddOnStore = defineStore({
    id: "voice-otp-addon",
    state: (): VoiceOtpAddOnState => ({
        showSelectVoiceOtpNumberModal: false,
        showSendTestOtpModal: false,
        showChangeVoiceOtpNumberModal: false,
        configDetails: null,
  }),

});