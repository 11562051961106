import { defineStore } from "pinia";
import { InviteTeamMemberPayload, PINIA_PERSIST_OPTIONS } from "../helpers";
import { useNumberStore } from "./numbers.store";
import { useBusinessStore } from "./businesses.store";
import { useUsersStore } from "./users.store";

interface Question {
    id: string;
    title: string;
    options: string[];
    rowType: string;
}

interface UtilsState {
    pendingSetupPayload: any;
    isActivatingNumber: boolean;
    isActivated: boolean;
    userSignUpSource: string;
    isRecommendedPlanMode?: boolean;
    signUpSource?: string;
    signInSource?: string;
    affiliateLinkCode?: string;
    affiliateLinkRef?: string;
    affiliateLinkId?: string;
    signUpFormData: {
        first_name: string;
        last_name: string;
        personal_email: string;
        mobile: string;
        id: string | null;
    };
    existingBusiness: any;
    newBusinessObject: any;
    showFullScreen: boolean;
    pendingMember?: InviteTeamMemberPayload;
    redirectToVerification?: boolean;
    youtubeVideos: null | any;
    mobileDetectionDone: boolean;
    redirectToVoiceOtp?: boolean;
    productsNotification: boolean;
    previousRoutePath?: string;
    isDeveloper: boolean;
    rerouteFrom: string;
    hasReloaded: boolean;
    allQuestions: Question[];
    allAgentsQuestions: Question[];
    isNewBusiness: boolean;
    totalCalls: number; // stores the total number of calls made by a user
    showNINModal: boolean;
}

export const useUtilsStore = defineStore({
    id: "utils",
    state: (): UtilsState => ({
        pendingSetupPayload: [],
        isActivatingNumber: false,
        isActivated: false,
        userSignUpSource: "", // get the sign up source from route query param eg sterling
        isRecommendedPlanMode: false,
        affiliateLinkCode: "",
        affiliateLinkRef: "",
        affiliateLinkId: "",
        signInSource: "",
        signUpFormData: {
            first_name: "",
            last_name: "",
            personal_email: "",
            mobile: "",
            id: null,
        },
        existingBusiness: null, // stores existing business
        newBusinessObject: null, // stores new business when number is purchased
        showFullScreen: false,
        youtubeVideos: null,
        mobileDetectionDone: false,
        redirectToVoiceOtp: false,
        productsNotification: true,
        previousRoutePath: "",
        isDeveloper: true,
        rerouteFrom: "",
        hasReloaded: false,
        allQuestions: [
            {
                id: "BusinessRoles",
                title: "What best describes you?",
                options: [
                    "Business Owner",
                    "Marketing/Sales",
                    "Human Resources",
                    "IT Operations",
                    "Customer Success/Support",
                    "Others",
                ],
                rowType: "box",
            },
            {
                id: "HandleBusinessCalls",
                title: "How do you currently handle business calls?",
                options: [
                    "We use regular mobile line(Airtel, MTN e.t.c)",
                    "We use a call center solution",
                ],
                rowType: "landscape",
            },
            {
                id: "ReasonForGettingPressoneCallCenter",
                title: "What is the #1 reason you are getting PressOne?",
                options: [
                    "Current Solution is too expensive",
                    "Current Solution does not work very well",
                    "Current solution lacks some key features",
                    "I don't know",
                    "I want to improve my brand with professional greeting",
                    "I want to see all the calls everyone is making",
                    "I want access to audio recordings of every call",
                ],
                rowType: "fourBox",
            },
            {
                id: "WhenToStartUsing",
                title: "When would you like to start using PressOne?",
                options: [
                    "Immediately",
                    "In 2 to 3 weeks",
                    "Not sure, depends on something else.",
                ],
                rowType: "box",
            },
            {
                id: "NumberOfCalls",
                title: "What is your number of weekly calls?",
                options: [
                    "0 - 100",
                    "101 - 200",
                    "201 - 500",
                    "501 - 1,000",
                    "1,001 - 5,000",
                    "Above 5,000",
                ],
                rowType: "box",
            },
            {
                id: "ServicesSold",
                title: "What product or service do you sell?",
                options: [
                    "E-Commerce",
                    "Financial Services",
                    "Logistics",
                    "Real Estate",
                    "Human Resources",
                    "Education",
                    "Healthcare",
                    "Software",
                    "Others",
                ],
                rowType: "box",
            },
        ],
        allAgentsQuestions: [
            {
                id: "HandleCompanyCalls",
                title: "How do you currently make calls for your company?",
                options: [
                    "I use my personal line",
                    "My company gave me an official phone",
                ],
                rowType: "landscape",
            },
            {
                id: "AirtimeSource",
                title: "How do you get airtime to make calls?",
                options: [
                    "I buy my airtime and the company refunds me",
                    "The company buys into my phone periodically",
                    "I use a post-paid line",
                ],
                rowType: "fourBox",
            },
            {
                id: "PeriodicReports",
                title: "Do you need to send periodic reports on calls to your manager?",
                options: [
                    "Yes, I send reports daily/weekly",
                    "Yes, I send reports monthly",
                    "No, I do not send reports.",
                ],
                rowType: "fourBox",
            },
            {
                id: "PreferredChoices",
                title: "Which of these would your rather have?",
                options: [
                    "Calls are automatically answered by voicemail after work hours",
                    "You never need to send reports anymore",
                    "You have an easy way to keep track of calls you made",
                    "You don’t need to use your personal number for business calls anymore",
                ],
                rowType: "landscape",
            },
        ],
        isNewBusiness: false, // check where the engagement is being filled from. False from popup
        totalCalls: 0,
        showNINModal: false,
    }),
    getters: {
        getShowBanner() {
            const numberStore = useNumberStore();
            const businessStore = useBusinessStore();
            const userStore = useUsersStore();
            if (
                userStore.currentUserBusinessLevel?.free_trial_data
                    ?.is_free_trial_user
            )
                return false;
            if (!numberStore.numbers || numberStore.numbers.length < 1) {
                return true;
            } else if (!businessStore.activeBusiness?.business.verified) {
                return true;
            }

            if (
                numberStore.subscriptionStatus === "past_due" ||
                numberStore.subscriptionStatus === "pending"
            ) {
                return true;
            }

            return false;
        },
    },
    actions: {
        setSetUpPayload(payload: any) {
            this.pendingSetupPayload = payload;
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
