import { defineStore } from "pinia";
import { createApiToken, fetchApiToken, getSummary } from "../../helpers";
import logger from "../../helpers/logger";
import { useAddonStore } from "./index.store";
import { useUsersStore } from "../users.store";

interface WidgetData {
    id: number;
    business_name: string;
    archived: boolean | null;
    last_modified: string;
    date_created: string;
    logo: string | null;
    page_message: string | null;
    jumbotron: string | null;
    tool_tip_message: string | null;
    button_message: string | null;
    contacts: string | null;
    call_line: string | null;
    secondary_text_color: string;
    primary_text_color: string;
    bottom_position: number | null;
    right_position: number | null;
    primary_bg_color: string | null;
    secondary_bg_color: string | null;
    is_active: boolean;
    webhook_url: string | null;
    business: number | null;
    addon_subscription: number | null;
    dial_plan: string;
}
interface LiveCallState {
    widgetTheme: WidgetData | any;
    gettingLiveCallMetrics: boolean;
    liveWidgetToken: string;
    isFreeUser: boolean;
    isWidgetOnly: boolean;
    displayMode: boolean;
}

export const useLiveCallWidgetStore = defineStore({
    id: "live-call",
    state: (): LiveCallState => ({
        widgetTheme: {},
        gettingLiveCallMetrics: false,
        liveWidgetToken: "",
        isFreeUser: false,
        isWidgetOnly: false,
        displayMode: false,
    }),
    getters: {
        hasAccess(): boolean {
            const addonStore = useAddonStore();
            const userStore = useUsersStore();
            return (
                addonStore.getAllEnhancedAddons.some(
                    (addon) =>
                        addon.title
                            .toLowerCase()
                            .includes("website live call addon") &&
                        addon.isPurchased
                ) ||
                userStore.currentUserBusinessLevel?.free_trial_data
                    ?.is_free_trial_user
            );
        },
    },

    actions: {
        async getMetrics(period: string, start?: string, end?: string) {
            try {
                this.gettingLiveCallMetrics = true;
                const getCallMetrics = await getSummary(period, start, end);
                this.gettingLiveCallMetrics = false;
                return getCallMetrics?.data;
            } catch (error) {
                this.gettingLiveCallMetrics = false;
                logger.error(error, "Get Call Metrics For Live Widget");
            }
        },
        async getCreateApiTokenForLiveWidget() {
            try {
                const response = await fetchApiToken();
                this.liveWidgetToken = response.data.public_key;
                if (!this.liveWidgetToken) {
                    const createApiResponse = await createApiToken();
                    this.liveWidgetToken = createApiResponse.data.public_key;
                }
            } catch (error) {
                try {
                    if (!this.liveWidgetToken) {
                        const createApiResponse = await createApiToken();
                        this.liveWidgetToken =
                            createApiResponse.data.public_key;
                    }
                } catch (createError) {
                    logger.error(
                        createError,
                        "Error during token creation in catch block"
                    );
                }

                logger.error(
                    error,
                    "Error during fetch/create LiveWidgetToken"
                );
            }
        },
    },
});
