import { TeamMember, InviteTeamMemberPayload } from "./../helpers/types/teams";
import { defineStore } from "pinia";
import { useNumberStore } from "./numbers.store";

interface TeamState {
  teamMembers: TeamMember[];
  teamMembersList: TeamMember[];
  showInvitationModal: boolean;
  selectedTeamMember?: any;
  selectedTeamMemberReceiverId?: number | null;
  refetchCallQueue?: boolean;
  hasSuccessfullyInvitedMember?: boolean;
  getRecieversUnderABusiness: any; // used for transfer
  selectedContactQueueTeamMembers?: []
  addNewTeamMemberPayload: InviteTeamMemberPayload | null;
}

export const useTeamStore = defineStore({
  id: "teammember",
  state: (): TeamState => ({
    teamMembers: [],
    teamMembersList: [],
    showInvitationModal: false,
    selectedTeamMember: null, // this is the user id of the selected team member
    selectedTeamMemberReceiverId: null, // this is the receiver id of the selected team member
    refetchCallQueue: false,
    hasSuccessfullyInvitedMember: false,
    getRecieversUnderABusiness: [],
    selectedContactQueueTeamMembers:[],
    addNewTeamMemberPayload: null,
  }),

  getters: {
    getAllTeamMembers: (state: TeamState) => {
      const { activeNumber } = useNumberStore();
      return [...state.teamMembers, activeNumber];
    },
  },
  persist: {
    storage: localStorage,
    paths: ['addNewTeamMemberPayload'],
  },
});
