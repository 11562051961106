import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import {
  MakePayment,
  Plans,
  Subscription,
  Subscriptions,
} from "../types/billings";
import { downloadBillingHistory, getActiveBusinessId } from "../queries";

const BASE_ENDPOINT = `${BASE_API}/api`;

export const getSubscriptionPlans = async () => {
  return (await fetchWrapper.get(
    `${BASE_ENDPOINT}/payments/subscription-plan/`
  )) as Plans;
};

export const getCurrentUserSubscriptions = async (): Promise<Subscriptions> => {
  let subscriptions = await fetchWrapper.get(`${BASE_ENDPOINT}/subscriptions/`);

  const data = subscriptions.data;

  return {
    count: data?.count,
    next: data?.next,
    results: data?.results,
    previous: data?.previous,
  };
};

export const getSubscription = async (
  id: string | number
): Promise<Subscription> => {
  let subscription = await fetchWrapper.get(
    `${BASE_ENDPOINT}/subscriptions/${id}/`
  );

  return subscription?.data!;
};

export const cancelBusinessSubscrition = async (id: string) => {
  let number = await fetchWrapper.post(
    `${BASE_ENDPOINT}/numbers/${id}/cancel_subscription/`
  );

  return number;
};

export const reActivateBusinessSubscrition = async (id: string) => {
  let response = await fetchWrapper.post(
    `${BASE_ENDPOINT}/numbers/${id}/re_activate_subscription/`
  );

  return response;
};

export const getBillingPaymentReports = async (filters = {}) => {
  let query = new URLSearchParams(filters);
  let queryString = query.toString();
  let business_id = getActiveBusinessId();
  let reports = await fetchWrapper.get(
    `${BASE_ENDPOINT}/payments/history/?business_id=${business_id}&${queryString}`
  );

  if (!reports?.data) return { data: [] };

  return reports?.data ?? [];
};

export const downloadBillingRecords = async (
  user_id: string,
  from_date: string,
  to_date: string
) => {
  try {
    await downloadBillingHistory(user_id, from_date, to_date);
  } catch (error) {
    console.log(error);
  }
};

export const getExistingBusiness = async () => {
  let businesses = await fetchWrapper.get(`${BASE_ENDPOINT}/business`);
  return businesses?.data ?? [];
};

export const makePayment = async (data: MakePayment) => {
  let payment = await fetchWrapper.post(
    `${BASE_ENDPOINT}/v2/update_business_number_subscription/`,
    { ...data }
  );
  return payment;
};

export const cancel_subscription = async ({
  activeBusinessNumberId,
}: {
  activeBusinessNumberId: string;
}) => {
  let canceled = await fetchWrapper.post(
    `${BASE_ENDPOINT}/numbers/${activeBusinessNumberId}/cancel_subscription/`
  );
  return canceled;
};

export const getCards = async (userId: string | number) => {
  let cards = await fetchWrapper.get(`${BASE_ENDPOINT}/users/${userId}/cards/`);

  return cards?.data ?? [];
};

export const addCard = async ({
  amount = 50,
  ...data
}: {
  business: string;
  payment_mode: string;
  amount?: number;
}) => {
  let cards = await fetchWrapper.post(`${BASE_ENDPOINT}/v2/top_up/`, {
    amount,
    ...data,
  });

  return cards?.data ?? [];
};

export const deleteCard = async ({
  userId,
  card_num,
}: {
  userId: string | number;
  card_num: Array<string>;
}) => {
  let cards = await fetchWrapper.post(
    `${BASE_ENDPOINT}/users/${userId}/delete_cards/`,
    { card_num }
  );

  return cards?.data ?? [];
};

export const getSubscriptionStatus = async () => {
  let subscriptionStatus = await fetchWrapper.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/status/`
  );
  return subscriptionStatus?.data?.data?.status ?? "";
};

export const getUnpaidInvoices = async (business_id?: number | string) => {
  if (getActiveBusinessId() && !business_id) {
    business_id = getActiveBusinessId();
  }
  let invoices = await fetchWrapper.public_get(
    `${BASE_ENDPOINT}/business/${business_id}/invoice/`
  );
  return invoices?.data?.data ?? [];
};

export const clearPendingInvoices = async (business_id?: number | string) => {
  if (getActiveBusinessId()) {
    business_id = getActiveBusinessId();
  }
  let paymentUrl = await fetchWrapper.public_post(
    `${BASE_ENDPOINT}/business/${business_id}/invoice/clear-pending/`
  );
  return paymentUrl?.data?.data;
};

export const getSubscriptionDetails = async () => {
  let subscriptiondetails = await fetchWrapper.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/`
  );
  return subscriptiondetails;
};
