import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/css/main.scss";
import App from "./App.vue";
import { loadFonts } from "./plugins/webfontloader";
import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from '@sentry/tracing';
import Notifications from "@kyvg/vue3-notification";
// import { AVPlugin } from "vue-audio-visual";
import "vuetify/styles";
import "./assets/css/iconsax/iconsax-icon/icons.css";

import "v-calendar/style.css";
import { dialerPlugin } from "./plugins/dialer";
import logger from "./helpers/logger";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";

// Use plugin defaults (optional)

loadFonts();

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(vuetify);
app.use(router);
app.use(Notifications);
app.use(dialerPlugin);

console.log(import.meta.env.MODE, "current mode");

if (import.meta.env.MODE !== "development") {
    try {
        Sentry.init({
            dsn: "https://0ceb1349d55bc6e784086af2e2de8562@o1125524.ingest.us.sentry.io/4506931959955456",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            ignoreErrors: [
                "Unhandled Promise Rejection: Connect aborted.",
                "Unhandled Promise Rejection: Disconnect aborted.",
                "Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
                "Uncaught Error: Registerer terminated. Unable to register.",
                "Unhandled Promise Rejection: Peer connection undefined.",
                "Registerer terminated. Unable to register.",
                "Connect aborted",
                "WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
                "Unhandled Promise Rejection: REGISTER request already in progress, waiting for final response",
                "REGISTER request already in progress, waiting for final response",
                "Unhandled Promise Rejection: Invalid state transition from Stopped to Stopped",
                "Failed to fetch dynamically imported module",
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["staging-new-pressone-web.netlify.app"],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0,
            // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    } catch (e) {
        logger.error(e, "Sentry init error");
        // console.dir(e)
    }
}

app.mount("#app");
app.use(setupCalendar, {});

// Use the components
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
