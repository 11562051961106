import { defineStore } from "pinia";
import { allCallData, CallInfo } from "../helpers/types/call";
import { Contacts, PINIA_PERSIST_OPTIONS } from "../helpers";
interface CallState {
    currentRequestForReload: string | null;
    selectedCallInfo: CallInfo | any;
    selectedCallContactDetails: Contacts | any;
    successSave: boolean;
    selectedIndex: number | null;
    callList?: allCallData | null;
    currentRequestQuery: string | null;
    pageIndex: null | number | undefined;
    showCallNotes: boolean;
    voiceMessagesList: any;
}
export const useCallStore = defineStore({
    id: "calls",
    state: (): CallState => ({
        currentRequestForReload: "",
        selectedCallInfo: null,
        selectedCallContactDetails: {},
        successSave: false, // when you save succesfully in callrecord,
        selectedIndex: null,
        pageIndex: null,
        currentRequestQuery: null,
        showCallNotes: false,
        voiceMessagesList: null,
    }),
    getters: {
        getNextUrl: (state) => {
            const urlParams = new URLSearchParams(
                state.currentRequestForReload?.split("?")[1]
            ); // Get the query parameters
            urlParams.set("page_index", (state.pageIndex! + 1).toString()); // Set the updated page index
            const baseUrl = state.currentRequestForReload?.split("?")[0]; // Get the base URL
            return `${baseUrl}?${urlParams.toString()}`;
        },
        prevNextUrl: (state) => {
            const urlParams = new URLSearchParams(
                state.currentRequestForReload?.split("?")[1]
            ); // Get the query parameters
            urlParams.set("page_index", (state.pageIndex! - 1).toString()); // Set the updated page index
            const baseUrl = state.currentRequestForReload?.split("?")[0]; // Get the base URL
            return `${baseUrl}?${urlParams.toString()}`;
        },
        getNewRecordNotification: (state) => {
            if (!state.voiceMessagesList || !state.voiceMessagesList?.length)
                return false;

            return !!state.voiceMessagesList?.find(
                (message: any) => !message.is_played
            );
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
