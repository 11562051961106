import { fetchWrapper } from "../../helpers/fetch-wrapper";
import { BASE_API } from "../../helpers/constants";

export const addNewCallNote = async (payload: any, useCallLogEndpoint: any) => {
    const withCallId = `${BASE_API}/api/telephony/call-notes/`;
    const withCallLog = `${BASE_API}/api/telephony/call-notes/with-log/`;

    return await fetchWrapper.post(
        useCallLogEndpoint ? withCallLog : withCallId,
        payload
    );
};

export const updateCallNote = async (payload: any, pk: any) => {
    return await fetchWrapper.patch(
        `${BASE_API}/api/telephony/call-notes/id/${pk}/`,
        payload
    );
};

// export const addNewContactNote = async (payload) => {
// 	return (await fetchWrapper.post(`${BASE_API}/api/telephony/call-notes/`, payload));
// };

// export const updateContactNote = async (payload, pk) => {
// 	return (await fetchWrapper.patch(`${BASE_API}/api/telephony/call-notes/id/${pk}/`, payload));
// };
