import { ResponseData } from "..";
import { BASE_API } from "../constants";
import { fetchWrapper } from "../fetch-wrapper";

export const fetchAllVideo = async (link?: string): Promise<ResponseData> => {
    if (link) return await fetchWrapper.get(link);
    else return await fetchWrapper.get(`${BASE_API}/api/user/demo-video/`);
};
export const searchDemoVideo = async (
    searchParam: string
): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/user/demo-video/?title=${searchParam}`
    );
};
