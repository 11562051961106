import { useBusinessStore, useDialerStore, useNumberStore } from "../stores";
// import { PressOne } from "@pressone/dialer";
import { UIState } from "@pressone/dialer/lib/types";
import { notify } from "@kyvg/vue3-notification";
import { PressoneInstance } from "../plugins/dialer";
import { inject } from "vue";
import logger from "./logger";
import { fetchCallSummary } from "./queries";
import { formatNigerianNumber, isNigerianNumber } from "./utils";
export function useConnectPhoneToSip() {
    const getPressoneInstance: any = inject("getPressoneInstance");
    const dialerStore = useDialerStore();
    const numberStore = useNumberStore();

    const reconnectPhone = async (id: any) => {
        dialerStore.isRegistered[id] = {
            ...dialerStore.isRegistered[id],
            isConnecting: true,
        };
        if (numberStore?.subscriptionStatus === "past_due") {
            notify({
                type: "error",
                text: "Account restricted due to outstanding payment",
            });
        } else {
            if (getPressoneInstance()) {
                try {
                    await getPressoneInstance().start(
                        numberStore.currentUserReceiverKey?.toString(),
                        [id]
                    );

                    getPressoneInstance().onRegistered = () => {
                        dialerStore.isRegistered[id] = {
                            isConnected: true,
                            isConnecting: false,
                        };
                    };
                    getPressoneInstance().onRegistrationFail = () => {
                        dialerStore.isRegistered[id] = {
                            isConnected: false,
                            isConnecting: false,
                        };
                    };
                    getPressoneInstance().onUnregistered = () => {
                        dialerStore.isRegistered[id] = {
                            isConnected: false,
                            isConnecting: false,
                        };
                    };
                } catch (e) {
                    logger.error(e, "Reconnect Phone");

                    notify({
                        text: `Encountered an error connecting number`,
                        title: "Error connecting..",
                        type: "error",
                    });
                    dialerStore.isRegistered[id] = {
                        isConnected: false,
                        isConnecting: false,
                    };
                }
            }
        }
    };
    // Reconnect phone when there is a disconnection
    function reconnectPhoneAfterDiconnection() {
        if (!navigator.onLine) {
            notify({
                text: "You are currently not online at the moment. Kindly connect to the internet and retry",
                type: "error",
            });
            return;
        }
        for (const id in dialerStore.isRegistered) {
            if (!dialerStore.isRegistered[id].isConnected) {
                try {
                    reconnectPhone(id);
                } catch (error) {
                    logger.error(error, "Reconnect Phone");
                }
            }
        }
    }

    function disconnectPhoneWhenOffline() {
        for (const id in dialerStore.isRegistered) {
            if (getPressoneInstance().state[id]) {
                getPressoneInstance().state[id].userAgent.stop();
                dialerStore.isRegistered[id].isConnected = false;
                dialerStore.isRegistered[id].isConnecting = false;
            }
        }
    }
    return {
        reconnectPhone,
        reconnectPhoneAfterDiconnection,
        disconnectPhoneWhenOffline,
    };
}
export const callNumber = async (
    number: string,
    pressoneInstance: PressoneInstance
) => {
    // revert back from any
    const numberStore = useNumberStore();
    const businessStore = useBusinessStore();
    if (!numberStore.activeNumber) {
        notify({
            text: "To proceed with the call, please ensure that you have purchased a business number",
            type: "warn",
        });
        return;
    }
    if (
        // if pressone users are using international numbers to call it must be in country code format
        numberStore.activeNumber?.business_number?.country_code.toLowerCase() !==
        "ng"
    ) {
        if (!isNigerianNumber(number) && !number.startsWith("+")) {
            notify({
                text: "Please ensure you add (+) with your country code before dialing.",
                type: "error",
            });
            return;
        } else {
            number = formatNigerianNumber(number);
        }
    }
    if (!businessStore.activeProfile?.business?.verified) {
        // check the total calls made for an unverified user and restrict when it is 10 in total
        const getSummary = await fetchCallSummary("today");
        const totalCall = getSummary?.data?.total_call;
        if (totalCall >= 10) {
            notify({
                text: "You have exceeded your daily limit for calls. To enjoy unlimited calls, please verify your account.",
                type: "error",
            });
            return;
        }
    }
    try {
        pressoneInstance()?.call(
            number,
            undefined,
            undefined,
            numberStore.activeNumber.business_number.phone_number
        );
    } catch (error) {
        logger.error(error, "callNumber");
    }
};
export const endCall = (pressoneInstance: PressoneInstance) => {
    try {
        pressoneInstance().endCall();
    } catch (error) {
        logger.error(error, "End Call");
    }
};
export const answerCall = (pressoneInstance: PressoneInstance) => {
    try {
        pressoneInstance().answer();
    } catch (error) {
        logger.error(error, "Answer Call");
    }
};
export const holdCall = (
    dialerState: UIState,
    pressoneInstance: PressoneInstance
) => {
    try {
        if (!dialerState.showHoldButton) return;
        if (dialerState.isHeld)
            pressoneInstance()?.getOngoingCallSession()?.unhold();
        else pressoneInstance()?.getOngoingCallSession()?.hold();
    } catch (error) {
        logger.error(error, "Hold Call");
    }
};
export const muteCall = (
    dialerState: UIState,
    pressoneInstance: PressoneInstance
) => {
    try {
        if (!dialerState.showMuteButton) return;
        if (dialerState.isMuted)
            pressoneInstance()?.getOngoingCallSession()?.unmute();
        else pressoneInstance()?.getOngoingCallSession()?.mute();
    } catch (error) {
        logger.error(error, "Mute Call");
    }
};
export const transferCall = (
    pressoneInstance: PressoneInstance,
    transferTo: number
) => {
    try {
        pressoneInstance().blindTransfer(transferTo);
    } catch (error) {
        logger.error(error, "Transfer Call");
    }
};
export const playDTMF = (
    pressoneInstance: PressoneInstance,
    input: number | string
) => {
    try {
        pressoneInstance().playDTMF(input.toString());
    } catch (error) {
        logger.error(error, "Play DMTF");
    }
};
export const sendDTMF = (
    pressoneInstance: PressoneInstance,
    input: number | string
) => {
    try {
        pressoneInstance().sendDTMF(input.toString());
    } catch (error) {
        logger.error(error, "Send DMTF");
    }
};
