import { defineStore } from "pinia";

interface KnowledgeBaseAddOnState {
    showManageTopicModal: boolean;
    showAddTopicModal: boolean;
  }

export const useKnowledgeBaseAddOnStore = defineStore({
    id: "knowledge-base-addon",
    state: (): KnowledgeBaseAddOnState => ({
        showManageTopicModal: false,
        showAddTopicModal: false,
  }),
  actions: {
    toggleOpenManageTopicModal() {
      this.showManageTopicModal =
        !this.showManageTopicModal;
    },
    toggleOpenAddTopicModal() {
      this.showAddTopicModal =
        !this.showAddTopicModal;
    },
  },

});