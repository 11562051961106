import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useTeamStore, useContactStore } from "../stores";
import { getInitials } from "./utils";
import { ContactDistribution, TeamMember } from ".";
import { notify } from "@kyvg/vue3-notification";
import {
  distributeBulkUploadContacts,
  distributeContacts,
} from "./queries/callQueue";
import logger from "./logger";
import emitter from "./integrations/emitter";

const loading = ref(false);

const callQueueLoading = ref(false);

const allContactsSelected = ref(false);

const selectedTeamMembers = ref<number[]>([]);

const selectedTeamUserQueue = ref();

const contactShare = ref<number[]>([]);

const showAddContactsModal = ref(false);

const bulkUploadLoading = ref(false);

const uploadProgressPercentage = ref(0);

const showContactUploadProgressmodal = ref(false);

const importCSVdialogVisible = ref(false)

export const useContactCallQueue = () => {
  const contactStore = useContactStore();

  const teamStore = useTeamStore();

  const { selectedContacts, selectedContactIDs } = storeToRefs(contactStore);

  const { teamMembers } = storeToRefs(teamStore);

  const fullInitials = (name: string) => {
    const fullInitials = getInitials(name);

    return fullInitials ? fullInitials : "";
  };

  const singleteamMember = computed(() => {
    // This takes care of generating the name for a single team member
    return teamMembers.value.find(
      (teamMember: TeamMember) =>
        teamMember.user == selectedTeamMembers.value[0]
    );
  });

  const totalContactsDistributed = computed(() => {
    return contactShare.value.reduce(
      (a: number, b: number) => Number(a) + Number(b),
      0
    );
  });

  const totalContactsSelected = computed(() => {
    return allContactsSelected.value
      ? contactStore.totalContacts
      : selectedContacts.value.length;
  });

  const distributeContactToQueue = async () => {
    let distribution = [];

    loading.value = true;

    for (let i = 0; i < selectedTeamMembers.value.length; i++) {
      distribution.push({
        agent_id: selectedTeamMembers.value[i],
        contact_count: contactShare.value[i],
      });
    }

    if (selectedTeamMembers.value.length == 1) {
      distribution[0].contact_count = totalContactsSelected.value;
    }

    let contacts;
    let payload;
    let response;

    // conditional to check if its an uploaded contact from the first element
    if (importContactMode.value) {
      contacts = selectedContacts.value;

      payload = {
        bulk_contact_data: { contacts },
        distribution,
      } as ContactDistribution;

      await importContactsWithProgress(
        handleDistributeBulkUploadContact,
        payload
      );
      // handleDistributeBulkUploadContact(payload)
    } else {
      contacts = allContactsSelected.value ? null : selectedContactIDs.value;

      payload = {
        contacts,
        distribution,
        all_contacts: allContactsSelected.value,
      } as ContactDistribution;

      response = await handleDistributeContactAPICall(payload);
    }

    loading.value = false;

    if (response == `success`) return "success" as string;
  };

  const handleDistributeContactAPICall = async (
    payload: ContactDistribution
  ) => {
    try {
      await distributeContacts(payload);

      selectedContacts.value = [];

      return "success";
    } catch (error) {
      logger.error(error, "Error while adding contacts to call queue");

      notify({
        text: "Error occured while adding contacts to call queue",
        type: "error",
      });
    }
  };

  const handleDistributeBulkUploadContact = async (
    payload: ContactDistribution
  ) => {
    try {
      bulkUploadLoading.value = true;

      await distributeBulkUploadContacts(payload);

      selectedContacts.value = [];

      bulkUploadLoading.value = false;

      return "success";
    } catch (error) {
      logger.error(error, "Error while adding contacts to call queue");

      notify({
        text: "Error occured while adding contacts to call queue",
        type: "error",
      });
      bulkUploadLoading.value = false;
      throw error;
    }
  };

  const importContactMode = computed(() => {
    return (!selectedContacts.value[0]?.hasOwnProperty("id") && importCSVdialogVisible.value) || false;
  });

  const importContactsWithProgress = async (callback?: any, payload?: any) => {
    showContactUploadProgressmodal.value = true;

    const intervalId = setInterval(async () => {
      uploadProgressPercentage.value++; // Increment the value

      if (uploadProgressPercentage.value > 50) {
        clearInterval(intervalId); // Clear the interval when the value exceeds 50

        try {
          // Execute the callback function
          uploadProgressPercentage.value = 80;

          await callback(payload);

          // Set value to 100 after a successful callback execution
          uploadProgressPercentage.value = 100;

          showAddContactsModal.value = false;
          notify({
            type: "success",
            text: "Contacts added to call queue successfully",
          });

          setTimeout(() => {
            showContactUploadProgressmodal.value = false;

            uploadProgressPercentage.value = 0;

            emitter.emit("closeimportCSVModal");
          }, 2000);
        } catch (error) {
          showContactUploadProgressmodal.value = false;

          uploadProgressPercentage.value = 0;

          logger.error(error, "Error while uploading Contacts");
          throw error;
        }
      }
    }, 100); // 2-second delay
  };

  return {
    fullInitials,
    loading,
    callQueueLoading,
    selectedTeamMembers,
    selectedTeamUserQueue,
    contactShare,
    distributeContactToQueue,
    totalContactsDistributed,
    totalContactsSelected,
    allContactsSelected,
    singleteamMember,
    showAddContactsModal,
    importContactMode,
    showContactUploadProgressmodal,
    uploadProgressPercentage,
    importCSVdialogVisible
  };
};
