export enum AMPLITUDE_EVENTS {
    // Onboarding Events
    CLICKED_SIGNUP_BTN = "Clicked Sign up button",
    BRASS_USER_SIGNUP = "Viewed Signup through Brass link",
    SCALEIN_USER_SIGNUP = "Viewed Signup through ScaleIn link",
    STERLING_USER_SIGNUP = "Viewed Signup through Sterling link",
    PRINTIVO_USER_SIGNUP = "Viewed Signup through Printivo link",
    SIDE_BRIEF_USER_SIGNUP = "Viewed Signup through Sidebrief link",
    AFFILIATE_SIGNUP = "Viewed Signup through Affiliate Referral",
    // Sign in events
    VIEWED_SIGN_IN = "Viewed Signin page", // done
    CLICKED_SIGNIN_BTN = "Clicked Signin", // done
    LOGIN = "User Signedin",
    WINBACK_CAMPAIGN_SIGNIN = "Winback Campaign Signin",

    //   Call Queue
    START_CALL_QUEUE = "Started Call Queue", // done
    PAUSE_CALL_QUEUE = "Paused Call Queue", //done
    RESUME_CALL_QUEUE = "Resumed Call Queue", //done
    // Recommended Plan Events
    VIEWED_RECOMMENDED_FORM = "Viewed Recommendation Form", // done
    VIEWED_RECOMMENDED_PLAN = "Viewed Recommended Plan", // done
    SELECTED_SOLO_PLAN = "Selected Plan: Solo ", // done
    SELECTED_STANDARD_PLAN = "Selected Plan: Standard ", // done
    SELECTED_GROWTH_PLAN = "Selected Plan: Growth ", // done
    SELECTED_INTERNATIONAL_PLAN = "Selected Plan: International ", // done
    SELECTED_SOLO_QUARTERLY_PLAN = "Selected Plan: Solo Quaterly", // done
    SELECTED_STANDARD_QUARTERLY_PLAN = "Selected Plan: Solo Quaterly", // done
    SELECTED_GROWTH_QUARTERLY_PLAN = "Selected Plan: Growth Quaterly", // done
    SELECTED_INTERNATIONAL_QUARTERLY_PLAN = "Selected Plan: International Quaterly", // done
    SELECTED_SOLO_ANNUAL_PLAN = "Selected Plan: Solo Anual", // done
    SELECTED_STANDARD_ANNUAL_PLAN = "Selected Plan: Standard Anual", // done
    SELECTED_GROWTH_ANNUAL_PLAN = "Selected Plan: Growth Anual", // done
    SELECTED_INTERNATIONAL_ANNUAL_PLAN = "Selected Plan: International Anual", // done
    // Subscription
    CLICKED_PAYMENT_BTN = "Clicked Payment button",
    // Number Setup
    VIEWED_NUMBER_SETUP = "Viewed number setup", // done
    CLICKED_PURCHASE_ADDITIONAL_NUMBER = "Clicked purchase additional number",
    COMPLETED_SETUP_FLOW = "Completed Setup Flow", // donne
    // Onboarding
    CLICKED_TEST_CALL = "Clicked Test call",
    CLICKED_WATCH_TOUR = "Clicked Watch Tour",
    CLICKED_INVITE_TEAM_MEMBER = "Clicked Invite Team Member",
    CLICKED_VERIFY_ACCOUNT = "Clicked Verify Account",
    COMPLETED_ACCOUNT_VERIFICATION = "Completed account verification",
    CLICKED_INCOMING_CALL_FLOW = "Clicked Incoming call flow",
    CLICKED_OUT_OF_OFFICE_FLOW = "Clicked Out-of-office flow",
    //   CALL
    OPEN_DIALLER = "Open dialer", // done
    CLOSED_DIALLER = "Close dialer", // done
    SIP_CONNECTED = "Sip connected", // done
    SIP_DISCONNECTED = "Sip disconnected", // done
    SIP_CONNECTION_ERR = "Sip connection error", // done
    //   Billing
    UPGRADED_SUBSCRIPTION = "Click Upgrade Subscription", // done
    CANCELLED_SUBSCRIPTION = "Click Cancel Subscription", // done
    CLICK_CANCEL_SUB = "click cancel subscription",
    //   INTEGRATIONS
    CLICK_CONNECT_TO_ZOHO_CRM = "Click connect to Zohocrm", // done
    CLICK_CONNECT_TO_HUBSPOT_CRM = "Click connect to Hubspot", // done
    CLICK_CONNECT_TO_FRESHDESK = "Click connect to Freshdesk", // done
    CLICK_CONNECT_TO_GOOGLE_CHROME_EXTENSION = "Click connect to Google Chrome Extension", // done
    CLICK_CONNECT_TO_PIPE_DRIVE = "Click connect to Pipe Drive", // done
    CLICK_CONNECT_TO_ZOHO_PHONE_BRIDGE = "Click connect to Zoho Phone Bridge", // done
    CLICK_CONNECT_TO_INTERCOM = "Click connect to Intercom", // done
    CLICK_CONNECT_TO_PRESSONE_WIDGET = "Click connect Pressone Widget", // done
    CLICK_CONNECT_TO_ZAPIER = "Click connect to Zapier", // done
    //   Personalization
    CREATED_TAG = "Created tag", // done
    CREATED_CALL_SCRIPT = "Created call script", // done
    WGH_REFERALL_SIGNUP = "New WGH referral signup",
    PRESSONE_WEBSITE_SIGNUP = "New PressOneWebsite signup",
    // Feedbck
    NEW_EXPERIENCE_FEEBACK_SUBMITTED = "New experience feedback submitted",
    HEARD_ABOUT_US = "Where did you hear about us",
    // Share provisioned number
    SHARE_NUMBER = "share-number",
    CLICK_REACTIVATE_SUBSCRIPTION = "Click reactivate subscription",
    RENEW_SUBSCRIPTION = "Click renew subscription",
    FIRST_CALL_RATINGS = "First Call Rating",
}
