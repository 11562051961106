// airtable.js
import axios from "axios";

const NEW_EXPERIENCE_FEEDBACK = "tbl17ge15NT5lLSzw"; //The Base ID of the table
const BASE_ID = "appdk4CZXCkJKxPxY"; //overrall base id
const token =
  "patt1pL3v0HYXm1sq.70faf38f32eac768c958c6875b85ac2f06bcf3a3f26dd0c239bee8e40a89d66a"; // token generated

export const sendCallFeedback = (data: any) => {
  const url = `https://api.airtable.com/v0/${BASE_ID}/${NEW_EXPERIENCE_FEEDBACK}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axios.post(url, { fields: data }, { headers });
};
