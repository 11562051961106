import { getActiveBusinessId } from "../../queries";
import { BASE_API } from "../../constants";

import { fetchWrapper } from "../../fetch-wrapper";

export const getAllAddonList = async (term: string) => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/plans/?term=${term}`
  );
};

export const getSubscribedAddonList = async () => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/`
  );
};

export const subscribeToAddon = async (payload: any) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/`,
    payload
  );
};

export const unsubscribeToAddon = async (addon_sub_id: number) => {
  return await fetchWrapper.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/${addon_sub_id}/`
  );
};
