import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { getActiveBusinessId } from "..";

export const fetchCallConfig = async (link?: string) => {
    if (link) return await fetchWrapper.get(link);
    else
        return await fetchWrapper.get(
            `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/?page_size=20`
        );
};

export const editCallConfig = async (payload: any) => {
    return await fetchWrapper.put(
        `${BASE_API}/api/incoming_call_flow/?business_number=${getActiveBusinessId()}/${
            payload.id
        }/`,
        payload
    );
};

export const createCallConfig = async (payload: any) => {
    return await fetchWrapper.post(
        `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/`,
        payload
    );
};
export const deleteCallConfig = async (dial_plan_id: string) => {
    return await fetchWrapper.delete(
        `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/${dial_plan_id}/`
    );
};
export const assignNumbers = async (
    dial_plan_id: string,
    payload: { business_numbers: number[] }
) => {
    return await fetchWrapper.put(
        `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/${dial_plan_id}/assign-number`,
        payload
    );
};
