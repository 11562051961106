import { ref } from "vue";
import {
    getContacts,
    getContact,
    createContact,
    editContact,
    searchContacts,
    filterContacts,
    deleteContact,
    getContactLogs,
    bulkDelete,
} from "./queries/contact";
import { TabFilterItemTypes, createContactType } from "./types/contact";
// import { useNumberStore } from "../stores";
import { getActiveBusinessId } from "./queries";
import { notify } from "@kyvg/vue3-notification";

// const getActiveBusinessNumberId = () => {
//   const numberStore = useNumberStore();
//   return numberStore.activeBusinessNumberId;
// };

export const listContacts = async (link?: string) => {
    const contacts = ref([]) as any;
    if (link) {
        await getContacts(getActiveBusinessId(), link).then(
            (res) => (contacts.value = res.data || [])
        );
    } else
        await getContacts(getActiveBusinessId()).then(
            (res) => (contacts.value = res.data || [])
        );
    return { contacts: contacts.value };
};

export const getSingleContact = async (contact_id: any) => {
    const contactDetails = ref({}) as any;
    await getContact(getActiveBusinessId(), contact_id).then(
        (res) => (contactDetails.value = res.data || {})
    );
    return { contact: contactDetails.value };
};

export const handleCreateContact = async (payload: createContactType) => {
    try {
        await createContact(payload, getActiveBusinessId());

        notify({
            text: "Contact created successfully",
            type: "success",
        });
    } catch (error: any) {
        notify({
            text: error,
            type: "error",
        });
    }
};

export const handleEditContacts = async (
    contactId: number,
    payload: object
) => {
    try {
        await editContact(contactId, payload, getActiveBusinessId());

        notify({
            text: "Contact updated successfully",
            type: "success",
        });
        window.location.reload();
    } catch (err) {
        notify({
            text: "Error while updating contact",
            type: "error",
        });
        console.log(err);
    }
};

export const handleSearchContacts = async (text: string) => {
    const contactsList = ref([]);
    const nextLink = ref<null | string>(null);
    await searchContacts(text, getActiveBusinessId()).then((res) => {
        contactsList.value = res.data.data || [];
        nextLink.value = res.data.next || null;
    });

    return { contacts: contactsList.value, next: nextLink.value };
};
export const filterContactList = async (filterType: TabFilterItemTypes) => {
    const contactsList = ref([]);
    const nextLink = ref<null | string>(null);
    if (filterType === "all") {
        await getContacts(getActiveBusinessId()).then((res) => {
            contactsList.value = res.data.data || [];
        });
    } else if (
        filterType === "recently_added" ||
        filterType === "recently_called"
    ) {
        await filterContacts(
            filterType,
            getActiveBusinessId(),
            "-date_created"
        ).then((res) => {
            contactsList.value = res.data.data || [];
            nextLink.value = res.data.next || null;
        });
    } else if (filterType === "my_contacts") {
        await filterContacts(
            filterType,
            getActiveBusinessId(),
            "contact_name"
        ).then((res) => {
            contactsList.value = res.data.data || [];
            nextLink.value = res.data.next || null;
        });
    }

    return { contacts: contactsList.value, next: nextLink.value };
};

export const handleDeleteContact = async (id: any) => {
    try {
        await deleteContact(id, getActiveBusinessId());

        notify({
            text: "Contact deleted successfully",
            type: "success",
        });
        setTimeout(() => {
            window.location.reload;
        }, 1000);
    } catch (error) {
        notify({
            text: "Error while deleting contact",
            type: "error",
        });
    }
};
export const handleBulkDelete = async (
    contact_ids: Array<number>
): Promise<void> => {
    try {
        await bulkDelete(getActiveBusinessId(), contact_ids);
        notify({
            text: "Contacts deleted successfully",
            type: "success",
        });
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    } catch (error) {
        notify({
            text: "Error while deleting contacts",
            type: "error",
        });
    }
};

export const contactLogs = async (phone_number: any) => {
    const callLogs = ref([]) as any;
    await getContactLogs(getActiveBusinessId(), phone_number).then(
        (res) => (callLogs.value = res.data || [])
    );

    return { contacts: callLogs.value };
};
