import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { useBusinessStore, useNumberStore } from "../../stores";
import { GreetingToSave } from "..";

interface ResponseData {
  [key: string]: any;
}

const getActiveBusinessNumberId = () => {
  const numberStore = useNumberStore();
  return numberStore.activeBusinessNumberId;
};
const getActiveBusinessId = () => {
  const businessStore = useBusinessStore();
  return businessStore.currentBusinessId;
};
export const fetchOnboardingStage = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/onboarding/`
  );
};
export const updateOnboardingStage = async (
  payload: {
    [key: string]: boolean;
  },
  id: number
): Promise<ResponseData> => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/onboarding/${id}/`,
    payload
  );
};
export const fetchProfessionalGreetings = async (
  type: string,
  uuid?: string
): Promise<ResponseData> => {
  const baseUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/voice-script/?type=${type}`;
  const url = uuid ? `${baseUrl}&uuid=${uuid}` : baseUrl; // only when I pass a valid uuid
  return await fetchWrapper.get(url);
};

export const removeVoiceScript = async (id: string) => {
  return await fetchWrapper.delete(`${BASE_API}/api/voice_scripts/?id=${id}`);
};

export const saveBasicGreeting = async (
  payload: GreetingToSave
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/request-greeting`,
    payload
  );
};

export const fetchGreetingsAccent = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/request-greeting/voice-accent`
  );
};
export const getAccess = async () => {
  return await fetchWrapper.get(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/time-access/`,
    null
  );
};

export const fetchIncomingCallFlow = async () => {
  return await fetchWrapper.get(
    `${BASE_API}/api/incoming_call_flow/?business_number=${getActiveBusinessNumberId()}`
  );
};

export const editIncomingCallFlow = async (payload: any) => {
  return await fetchWrapper.put(
    `${BASE_API}/api/incoming_call_flow/?business_number=${getActiveBusinessNumberId()}/${
      payload.id
    }/`,
    payload
  );
};

export const deleteIncomingCallFlow = async (id: number) => {
  return await fetchWrapper.delete(
    `${BASE_API}/api/incoming_call_flow/?business_number=${getActiveBusinessNumberId()}/${id}/`
  );
};
export const fetchOutOfOfficeFlow = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/open_hour/`
  );
};
export const updateOutOfOffice = async (payload: any, id: string | number) => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/open_hour/${id}/`,
    payload
  );
};
export const createOutOfOffice = async (payload: any) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/open_hour/`,
    payload
  );
};
export const deleteOutOfOfficeCallFlow = async (id: number) => {
  return await fetchWrapper.delete(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/open_hour/${id}/`
  );
};
export const setDefaultOutOfOfficeFlow = async (id: number) => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/numbers/${getActiveBusinessNumberId()}/open_hour/${id}/default/`
  );
};
// incoming call flow
export const fetchIncomingFlow = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/number/${getActiveBusinessNumberId()}/incoming-call-configuration/`
  );
};
export const updateIncomingFlow = async (payload: any) => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/number/${getActiveBusinessNumberId()}/incoming-call-configuration/`,
    payload
  );
};
export const createIncomingFlow = async (payload: any) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/number/${getActiveBusinessNumberId()}/incoming-call-configuration/`,
    payload
  );
};
export const deleteIncomingFlow = async (id: number) => {
  return await fetchWrapper.delete(
    `${BASE_API}/api/number/${getActiveBusinessNumberId()}/incoming-call-configuration/${id}/`
  );
};
export const setDefaultIncomingFlow = async (id: number) => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/number/${getActiveBusinessNumberId()}/incoming-call-configuration/${id}/activate/`
  );
};
