import { defineStore } from "pinia";
import { decryptData, generateKeyPair } from "../helpers/encryptionKeys";
import {
    AMPLITUDE_EVENTS,
    fetchSipInfo,
    fetchSipNewInfo,
    getReceiverNumbers,
    getSubscriptionStatus,
    listContacts,
    trackAmplitudeEvent,
} from "../helpers";
import { useNumberStore, useTeamStore, useUsersStore, useUtilsStore } from ".";
import { DEFAULT_UI_STATE, PressOne } from "@pressone/dialer";
import { PressOneSipCredentials, UIState } from "@pressone/dialer/lib/types";
import { useCallQueue } from "../helpers";
import { PressoneInstance } from "../plugins/dialer";
import { notify } from "@kyvg/vue3-notification";
import emitter from "../helpers/integrations/emitter";
import logger from "../helpers/logger";
// import openReplay from "../helpers/openReplay";
//
interface DialerState {
    isRegistered: {
        [key: string]: {
            isConnected: boolean;
            isConnecting: boolean;
        };
    };
    dialerState: UIState;
    sipCredentials: { [key: string]: PressOneSipCredentials };
    showDialerModal: boolean;
    callDirection: string;
    callQueueIntervalTimer: undefined | number;
    callQueueTime: number;
    dialerModal: boolean;
    activeCallToEdit: any;
    isCallQueue: boolean;
    strength: number;
    savedNumberSucessfully: boolean;
    showOutboundModal: boolean;
    hasCalledPermissions: boolean;
    permissionsArray: { [key: string]: string } | any;
    numberToCall: string;
    getActiveCallInstanceKey: any;
    getOutgoingXCallID: string | null;
    showCallInfo: boolean;
    savedContacts: any;
    callSource: string;
    showFeedback: boolean;
}
const dialerWorker = new Worker("/dialer-worker.js");

export const useDialerStore = defineStore({
    id: "dialer",
    state: (): DialerState => ({
        isRegistered: {},
        dialerState: DEFAULT_UI_STATE,
        sipCredentials: {},
        showDialerModal: false,
        callDirection: "null",
        callQueueIntervalTimer: undefined,
        callQueueTime: 16,
        dialerModal: false,
        activeCallToEdit: null,
        isCallQueue: false,
        strength: 0,
        savedNumberSucessfully: false,
        showOutboundModal: false,
        hasCalledPermissions: false, // added it here cos it doesnt persist and updates evry time we refresh
        permissionsArray: null, // added it here cos it doesnt persist and updates evry time we refresh
        numberToCall: "",
        getActiveCallInstanceKey: "",
        getOutgoingXCallID: null,
        showCallInfo: false,
        savedContacts: {},
        callSource: "",
        showFeedback: false,
    }),
    getters: {
        phonesConnected(): any {
            const total = this.sipCredentials
                ? Object?.keys(this.sipCredentials).length
                : (0 as number);
            let connected = 0;
            if (!this.isRegistered) {
                return {
                    status: false,
                    connected: 0,
                    total: 0,
                };
            }
            for (const key in this.isRegistered) {
                if (this.isRegistered.hasOwnProperty(key)) {
                    if (this.isRegistered[key].isConnected === true) {
                        connected++;
                    }
                }
            }
            return {
                status: connected === total,
                connected,
                total,
            };
        },
        getActiveCallBusinessNumber(): number | null {
            // get business id based in active call receiver key
            if (
                this.sipCredentials?.hasOwnProperty(
                    this.getActiveCallInstanceKey
                )
            ) {
                return this.sipCredentials[this.getActiveCallInstanceKey]
                    .business_id;
            } else {
                return null; // Return null if key is not found
            }
        },
        getNoTrunkCreds(): any {
            for (const key in this.sipCredentials) {
                if (this.sipCredentials[key].business_id === 999) {
                    return { [key]: this.sipCredentials[key] };
                }
            }
            return null;
        },
        getLiveCallConnectionStatus(): any {
            // used to populate connection table
            if (
                this.getNoTrunkCreds &&
                Object.keys(this.getNoTrunkCreds).length > 0
            ) {
                const getKeyOfNoTrunkCred = Object.keys(
                    this.getNoTrunkCreds
                )[0];
                return this.isRegistered[getKeyOfNoTrunkCred] || null;
            }
            return null;
        },
    },
    actions: {
        async getSipInfo(number: { id: string | number }) {
            try {
                const keypair = await generateKeyPair();
                const publickey = keypair.publicKey;
                const userStore = useUsersStore();

                const payload = {
                    public_key: publickey,
                    receiver: number.id,
                    mobile: userStore?.currentUserBusinessLevel?.mobile,
                };

                try {
                    const data = await fetchSipInfo(payload); // get sip credentials fro receiver line
                    const decryptedPassword = await decryptData(
                        keypair.privateKey,
                        data.data.password
                    ); // decrypt password from reciever line
                    const fetchedSipInfo: PressOneSipCredentials | any =
                        data.data;
                    fetchedSipInfo.password = decryptedPassword; // update new password
                    return fetchedSipInfo;
                } catch (error) {
                    logger.error(error, "Get Sip Info");
                }
            } catch (error) {
                logger.error(error, "Get Sip Info");
            }
        },
        async getSipNewInfo() {
            // New endpoint
            const numberStore = useNumberStore();
            try {
                const keypair = await generateKeyPair();

                const publickey = keypair.publicKey;

                const newPayload = {
                    public_key: publickey,
                    is_no_trunk_team: false,
                };

                try {
                    const getNewInfo = await fetchSipNewInfo(newPayload);
                    const fetchedSipInfo = await Promise.all(
                        getNewInfo.data.map(async (creds: any) => {
                            return {
                                ...creds,
                                password: await decryptData(
                                    keypair.privateKey,
                                    creds.password
                                ),
                            };
                        })
                    );
                    const result = {} as any;

                    // fetchedSipInfo.forEach((sipCreds) => {
                    //     let matchFound = false;
                    //     for (const number of numberStore?.numbers!) {
                    //         if (
                    //             number?.line?.domain === sipCreds.domain &&
                    //             number?.line?.username == sipCreds.extension
                    //         ) {
                    //             result[number.id] = {
                    //                 password: sipCreds.password,
                    //                 extension: sipCreds.extension,
                    //                 username: sipCreds.username,
                    //                 host: sipCreds.host,
                    //                 domain: sipCreds.domain,
                    //                 port: sipCreds.port,
                    //                 protocol: sipCreds.protocol,
                    //                 business_id: number.business_number.id,
                    //             };
                    //             matchFound = true;
                    //             break;
                    //         }
                    //     }
                    //     if (!matchFound) {
                    //         const randomId =
                    //             Math.floor(Math.random() * 1000) + 1; // random id between 1 and 1000 to use as instance key in dialer
                    //         result[randomId] = {
                    //             password: sipCreds.password,
                    //             extension: sipCreds.extension,
                    //             username: sipCreds.username,
                    //             host: sipCreds.host,
                    //             domain: sipCreds.domain,
                    //             port: sipCreds.port,
                    //             protocol: sipCreds.protocol,
                    //             business_id: 999,
                    //         };
                    //     }
                    // });

                    for (const number of numberStore?.numbers!) {
                        for (const sipCreds of fetchedSipInfo) {
                            if (
                                number?.line?.domain === sipCreds.domain &&
                                number?.line?.username == sipCreds.extension
                            ) {
                                result[number.id] = {
                                    password: sipCreds.password,
                                    extension: sipCreds.extension,
                                    username: sipCreds.username,
                                    host: sipCreds.host,
                                    domain: sipCreds.domain,
                                    port: sipCreds.port,
                                    protocol: sipCreds.protocol,
                                    business_id: number.business_number.id,
                                };
                            }
                        }
                    }

                    const credentials = Object.values(result) as any;

                    const copiedfetchedSipInfo = [...fetchedSipInfo];

                    for (let i = 0; i < copiedfetchedSipInfo.length; i++) {
                        const findLivewidget = credentials.find(
                            (credential: any) =>
                                credential?.password ===
                                copiedfetchedSipInfo[i]?.password
                        );

                        if (!findLivewidget) {
                            const randomId =
                                Math.floor(Math.random() * 1000) + 1; // random id between 1 and 1000 to use as instance key in dialer
                            result[randomId] = {
                                password: copiedfetchedSipInfo[i].password,
                                extension: copiedfetchedSipInfo[i].extension,
                                username: copiedfetchedSipInfo[i].username,
                                host: copiedfetchedSipInfo[i].host,
                                domain: copiedfetchedSipInfo[i].domain,
                                port: copiedfetchedSipInfo[i].port,
                                protocol: copiedfetchedSipInfo[i].protocol,
                                business_id: 999,
                            };
                        }
                    }

                    return result;
                } catch (error) {
                    logger.error(error, "Get Sip Info New Implementation");
                }
            } catch (error) {
                logger.error(error, "Get Sip Info New Implementation");
            }
        },

        async fetchSipCredentials() {
            // fetch credentials and return only data with valid cred
            try {
                const sipCredentials = await this.getSipNewInfo();
                return sipCredentials;
            } catch (error) {
                return {};
            }
        },
        //
        showModalForFeedback() {
            const utilsStore = useUtilsStore();
            if (utilsStore.totalCalls > 0) return;
            this.showFeedback = true;
        },
        async connectSipToPhone(): Promise<PressOne | PressoneInstance | void> {
            const callQueue = useCallQueue;
            const numberStore = useNumberStore();
            const teamMember = useTeamStore();
            const utilsStore = useUtilsStore();

            const selectedInstanceKey = numberStore?.currentUserReceiverKey; // current active number key
            const status = await getSubscriptionStatus();

            if (status === "past_due") {
                notify({
                    type: "error",
                    text: "Account restricted due to outstanding payment",
                });
            } else {
                try {
                    this.sipCredentials = await this.fetchSipCredentials();
                    const instanceKeys: string[] = Object?.keys(
                        this.sipCredentials
                    );

                    if (navigator.onLine) {
                        for (let i = 0; i < instanceKeys.length; i++) {
                            this.isRegistered[instanceKeys[i]] = {
                                isConnected: false,
                                isConnecting: true,
                            };
                        }
                        const pressone = new PressOne(
                            {
                                sipCredentials: this.sipCredentials,
                            },
                            {
                                debug: false,
                                worker: dialerWorker,
                                closeDialerAfter: 3000,
                                autoRejectIncomingCallsWhenSessionOngoing: true,
                            }
                        );

                        const handleUiStateUpdate = (newState: UIState) => {
                            Object.assign(this.dialerState, {
                                ...this.dialerState,
                                ...newState,
                            });
                        };
                        // pressone.logger = (args: any) => {
                        //     openReplay.event(args);
                        // };
                        const handleRegistration = (
                            instanceKey: string,
                            isConnected: boolean
                        ) => {
                            this.isRegistered[instanceKey] = {
                                isConnected,
                                isConnecting: false,
                            };

                            trackAmplitudeEvent(
                                isConnected
                                    ? AMPLITUDE_EVENTS.SIP_CONNECTED
                                    : AMPLITUDE_EVENTS.SIP_CONNECTION_ERR
                            );
                        };

                        const handleUnregistration = (instanceKey: string) => {
                            this.isRegistered[instanceKey] = {
                                isConnected: false,
                                isConnecting: false,
                            };
                            trackAmplitudeEvent(
                                AMPLITUDE_EVENTS.SIP_DISCONNECTED
                            );
                            pressone.state[instanceKey]?.userAgent?.register();
                        };
                        await pressone.start(
                            selectedInstanceKey?.toString(),
                            instanceKeys
                        );

                        pressone.onUiStateUpdate = handleUiStateUpdate;

                        pressone.onRegistered = (instanceKey) =>
                            handleRegistration(instanceKey, true);

                        pressone.onRegistrationFail = (instanceKey) =>
                            handleRegistration(instanceKey, false);

                        pressone.onUnregistered = handleUnregistration;

                        pressone.onCallConnecting = async () => {
                            if (!this.isCallQueue) this.showDialerModal = true;

                            this.getActiveCallInstanceKey =
                                pressone?.getActiveCallInstanceKey(); // get receiver key of active call
                            const agents = await getReceiverNumbers(
                                this.getActiveCallBusinessNumber?.toString()
                            ); // get all the receivers under this business number to make transfer
                            teamMember.getRecieversUnderABusiness = agents.data;
                            trackAmplitudeEvent(AMPLITUDE_EVENTS.OPEN_DIALLER);
                        };

                        pressone.onCallEnded = () => {
                            this.callDirection = "";
                            this.showCallInfo = false;
                            this.dialerModal = false;
                            this.showModalForFeedback();
                            if (
                                this.isCallQueue &&
                                callQueue.callQueueCopy?.value?.length > 0
                            ) {
                                this.startCallQueueTimer();
                            } else {
                                this.callQueueTime = 0;
                            }
                            trackAmplitudeEvent(
                                AMPLITUDE_EVENTS.CLOSED_DIALLER
                            );
                            this.activeCallToEdit = {};
                            this.getOutgoingXCallID = null;
                            this.savedNumberSucessfully = false; // go back to initial state
                            setTimeout(
                                () => {
                                    this.showDialerModal = false;
                                    this.callSource = "";
                                    this.showFeedback = false;
                                },
                                utilsStore.totalCalls > 0 ? 3000 : 5000 // delay the timer to allocate time for user to fill rating
                            );
                            emitter.emit("saveNote");
                            emitter.emit("getCallHistory"); // if call ends
                            if (!this.isCallQueue)
                                this.showOutboundModal = true;
                        };

                        pressone.onOutgoingCall = () => {
                            this.showDialerModal = true; // called mutliple times
                            this.callDirection = "outgoing";
                        };
                        pressone.onIncomingCall = () => {
                            this.callDirection = "incoming";
                            this.showDialerModal = true;
                            trackAmplitudeEvent(AMPLITUDE_EVENTS.OPEN_DIALLER);
                        };
                        pressone.onCallFailed = () => {
                            this.showModalForFeedback();

                            setTimeout(
                                () => {
                                    this.showDialerModal = false;
                                    this.callSource = "";
                                    this.showFeedback = false;
                                },
                                utilsStore.totalCalls > 0 ? 3000 : 5000 // delay the timer to allocate time for user to fill rating
                            );
                            if (this.isCallQueue) this.startCallQueueTimer();
                            else this.showOutboundModal = true;
                            emitter.emit("getCallHistory"); // if call fails
                        };
                        pressone.onCallAccepted = (e) => {
                            //  using this event to get the id for outbound
                            this.showCallInfo = true;
                            this.getOutgoingXCallID =
                                e?.response?.headers["X-Call-Sid"][0].raw;
                        };
                        return pressone;
                    } else {
                        notify({
                            text: "You are currently not online at the moment. Kindly connect to the internet and to make calls",
                            type: "error",
                        });

                        for (let key in this.isRegistered) {
                            this.isRegistered[key].isConnected = false;
                            this.isRegistered[key].isConnecting = false;
                        }
                    }
                } catch (error) {
                    logger.error(error, "Cannot Connect");
                    // openReplay.event(`${error}`, "Attempt to Connect");
                    for (let key in this.isRegistered) {
                        this.isRegistered[key].isConnected = false;
                        this.isRegistered[key].isConnecting = false;
                    }
                }
            }
        },
        async handleOnlineStatusChange() {
            try {
                await this.connectSipToPhone();
            } catch (error) {
                logger.error(error, "Error handling online status change:");
            }
        },
        startCallQueueTimer() {
            this.callQueueTime = 16;
            const { nextActiveContact, callStateName } = useCallQueue;
            if (nextActiveContact.value?.contact_id) {
                if (callStateName.value === "Pause") {
                    this.callQueueIntervalTimer = setInterval(() => {
                        if (this.callQueueTime > 0) {
                            this.callQueueTime--;
                        }
                    }, 1000) as any;
                }
            } else {
                this.callQueueTime = 0;
            }
        },
        async fetchContact() {
            // to add contact in dialer. Used this incase customer dial before contact is populated in the store
            try {
                const { contacts } = await listContacts();
                this.savedContacts = contacts ?? {};
            } catch (error) {
                logger.error(error, "Fetch contact in dialer");
            }
        },
    },
});
