<template>
  <v-app>
    <v-main>
      <ActiveCall v-if="showDialer" v-show="dialerStore.showDialerModal" />
      <notifications class="notification" />
      <router-view />
      <Feedback class="position-absolute" v-if="showFeedBackModal" />
      <v-dialog
        v-model="showPostOnboarding"
        class="d-flex justify-center align-center"
        width="500"
        persistent
      >
        <OnboardingUpdate @closeModal="showPostOnboarding = false" />
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import ActiveCall from "./components/Shared/ActiveCall.vue";

import Feedback from "./components/Shared/Feedback.vue";
import {
  defineComponent,
  onUnmounted,
  onMounted,
  inject,
  watch,
  ref,
} from "vue";
import {
  useAuthStore,
  useDialerStore,
  useUsersStore,
  useNumberStore,
  useActivityStore,
  useUtilsStore,
  useBusinessStore,
  useAddonStore,
} from "./stores";
import { useRouter, useRoute } from "vue-router";
import { PressoneInstance } from "./plugins/dialer";
import { PressOne } from "@pressone/dialer";
import {
  handleFetchUserDetails,
  useCallQueue,
  getPendingNumberPayment,
  getPermissions,
  fetchAllVideo,
  useConnectPhoneToSip,
  getSubscriptionStatus,
  fetchIndividualCallLength,
} from "./helpers";
import logger from "./helpers/logger";
import { updateSessionActivity } from "./helpers/queries/teamMembers";
import { computed } from "vue";
import { useFreeLiveCallRedirection } from "./helpers/developer-program";
import { notify } from "@kyvg/vue3-notification";
import OnboardingUpdate from "./components/Shared/banners/OnboardingUpdate.vue";
export default defineComponent({
  components: { ActiveCall, Feedback, OnboardingUpdate },
  setup() {
    const numbersStore = useNumberStore();
    const businessStore = useBusinessStore();
    const userStore = useUsersStore();
    const dialerStore = useDialerStore();
    const activityStore = useActivityStore();
    const { isAuthenticated } = useAuthStore();
    const addonStore = useAddonStore();
    const authStore = useAuthStore(); // realize this is more reactive than the one on top. this is better
    // const contactStore = useContactStore();

    const showPostOnboarding = ref(false);

    const { handleTokenRedirection } = useFreeLiveCallRedirection();

    const showFeedBackModal = computed(() => {
      return (
        userStore.showFeedBackModal &&
        !userStore?.currentUserBusinessLevel?.has_completed_feedback &&
        authStore.isAuthenticated &&
        businessStore.businesses &&
        businessStore.businesses?.length > 0 &&
        businessStore.activeProfile?.business?.verified &&
        !userStore?.currentUserBusinessLevel?.is_free_trial_user
      );
    });
    const showDialer = computed(() => {
      return (
        authStore.isAuthenticated &&
        !dialerStore.isCallQueue &&
        (dialerStore.callSource.toLowerCase() !== "call_queue" ||
          callQueue.value.length < 1)
      );
    });

    const router = useRouter();
    const route = useRoute();
    const utilsStore = useUtilsStore();
    const engagementFormStatus = ref<boolean | null>(null);
    const { callQueue } = useCallQueue;

    const previousRoutePath = router?.options?.history?.state?.back;
    const setPressoneInstance = inject<
      (data: PressoneInstance | PressOne | void) => void
    >("setPressoneInstance");
    const handleOnlineStatusChange = async () => {
      try {
        if (setPressoneInstance)
          setPressoneInstance(await dialerStore.connectSipToPhone());
      } catch (error) {
        logger.error(error, "Error handling online status change:");
      }
    };

    // End Watch for change in connection
    const { reconnectPhoneAfterDiconnection, disconnectPhoneWhenOffline } =
      useConnectPhoneToSip();

    const openEngagementModal = () => {
      if (businessStore.activeProfile) {
        if (
          !businessStore.activeProfile?.business?.has_filled_engagement_form &&
          route.path !== "/managers/engagement" &&
          (userStore.user_role === "owner" || userStore.user_role === "manager")
        ) {
          setTimeout(() => {
            showPostOnboarding.value = true;
          }, 10000);
        }
      } else return;
    };
    onMounted(async () => {
      useCallQueue.isFetching.value = true;
      if (isAuthenticated) {
        try {
          openEngagementModal();
          await handleFetchUserDetails();

          // if (userStore.user_role == "agent") {
          //   useCallQueue.isFetching.value = true;

          //   const callQueues = await getAgentCallQueue(
          //     businessStore.currentUserId
          //   );

          //   if (!callQueues.data?.length) {
          //     await createCallQueue({
          //       created_by_id: businessStore.currentUserId,
          //       name: `call-queue-${businessStore.currentUserId}`,
          //       agent_id: businessStore.currentUserId,
          //       limit: 0,
          //     });
          //   }
          //   useCallQueue.listCallQueueAgent();

          //   await listContacts().then((res) => {
          //     contactStore.allContacts = res.contacts.data;
          //   });
          // }
          const status = await getSubscriptionStatus();
          numbersStore.subscriptionStatus = status as string;

          engagementFormStatus.value =
            userStore?.currentUserBusinessLevel?.has_filled_engagement_form;

          if (userStore.user_role === "agent" && !engagementFormStatus.value) {
            router.push("/agents/engagement");
          }
          // if (
          //   (userStore.user_role === "owner" ||
          //     userStore.user_role === "manager") &&
          //   !engagementFormStatus
          // ) {
          //   router.push("/managers/engagement");
          // }
          if (!businessStore?.activeProfile?.business?.verified)
            userStore.showFeedBackModal = false;

          const pendingSetupResponse = await getPendingNumberPayment();
          const pendingNumberSetup = pendingSetupResponse.data;

          utilsStore.setSetUpPayload(pendingNumberSetup.data);
          if (pendingNumberSetup?.data?.length) {
            userStore.user_role = "owner";
            router.push("/managers/provision-number");
          }

          await handleFetchUserDetails();
          businessStore.getBusiness(); // only update manager for now
          await numbersStore.getUserPhonesFromBusiness();
          userStore.permissions = await getPermissions();
          activityStore.connectDB();
          await addonStore.fetchAllAddons();
          await addonStore.fetchSubscribedAddons();
          await handleUpdateSessionActivity();
          // const getSummary = await fetchCallSummary("this_year"); // revert to this when agent query is avaialable
          // utilsStore.totalCalls = getSummary?.data?.total_call;
          // activityStore.listenDB();
          if (numbersStore.numbers?.length) {
            // only for people with receivers
            const response = await fetchIndividualCallLength();
            utilsStore.totalCalls = response?.data?.total;
          }
          if (
            (numbersStore?.numbers?.length !== 0 &&
              previousRoutePath !== "/otp") ||
            userStore.currentUserBusinessLevel?.free_trial_data
              ?.is_free_trial_user
          ) {
            businessStore.getBusiness(); // only update manager for now
            await numbersStore.getUserPhonesFromBusiness();
            // get all number
            if (numbersStore?.subscriptionStatus === "past_due") {
              notify({
                type: "error",
                text: "Account restricted due to outstanding payment",
              });
              return;
            }
            handleOnlineStatusChange(); // run initially on page mount
            window.addEventListener("online", handleOnlineStatusChange); // run everytime there is a network change
            window.addEventListener("offline", disconnectPhoneWhenOffline); // // run everytime there is a network change
            await dialerStore.fetchContact();
            const demoVideosResponse = await fetchAllVideo();
            utilsStore.youtubeVideos = demoVideosResponse.data;
            setInterval(() => {
              reconnectPhoneAfterDiconnection();
            }, 15000);
          }

          // get subscription status
        } catch (error) {
          logger.error(
            error,
            "An error occured with asynchronous calls on Mounted"
          );
        }
      }
    });

    watch(
      () => userStore.showFeedBackModal,
      (newValue: any) => {
        if (
          !newValue &&
          !userStore.currentUserBusinessLevel?.has_completed_feedback
        ) {
          setTimeout(() => {
            userStore.showFeedBackModal = true;
          }, 8 * 60 * 60 * 1000); // show this popup back at least after 5 hrs if users havent submitted feedback
        }
      }
    );

    watch(
      // Watch the route for token to redirect free users
      () => route.query,
      async (to: any) => {
        if (to.token) {
          try {
            await handleTokenRedirection(to.token);
          } catch (error) {
            logger.error(error, "Redirect from landing page");
          }
        }
      }
    );

    const handleUpdateSessionActivity = async () => {
      await updateSessionActivity();
      setInterval(async () => {
        await updateSessionActivity();
      }, 300000);
    };

    onUnmounted(async () => {
      if (isAuthenticated) {
        window.removeEventListener("online", handleOnlineStatusChange);
        window.removeEventListener("offline", handleOnlineStatusChange);
      }
    });
    return {
      dialerStore,
      activityStore,
      isAuthenticated,
      authStore,
      userStore,
      businessStore,
      showFeedBackModal,
      callQueue,
      showDialer,
      showPostOnboarding,
    };
  },
});
</script>

<style></style>
