import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { TabFilterItemTypes, createContactType } from "../types/contact";
import { getActiveBusinessId } from "../queries";

interface ResponseData {
    [key: string]: any;
}

export const getContacts = async (
    business_id: string | number,
    link?: string
): Promise<ResponseData> => {
    if (link) {
        return await fetchWrapper.get(link);
    } else
        return await fetchWrapper.get(
            `${BASE_API}/api/business/${business_id}/contacts/?page_index=1&page_size=100&ordering=name`
        );
};

export const getContact = async (
    business_id: string | number,
    contact_id: any
): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`
    );
};

export const searchContacts = async (
    payload: string,
    business_id: string | number
): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/business/${business_id}/contacts/?search=${payload}&ordering=name`
    );
};
export const filterContacts = async (
    payload: TabFilterItemTypes,
    business_id: string | number,
    orderingType: string
): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/business/${business_id}/contacts/?page_size=50&filter=${payload}&ordering=${orderingType}`
    );
};
export const createContact = async (
    payload: createContactType,
    id: string | number
): Promise<ResponseData> => {
    return await fetchWrapper.post(
        `${BASE_API}/api/business/${id}/contacts/`,
        payload
    );
};

export const editContact = async (
    contact_id: number,
    payload: any,
    business_id: string | number
): Promise<ResponseData> => {
    return await fetchWrapper.patch(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`,
        payload
    );
};

export const getContactCsv = async (): Promise<any> => {
    return await fetchWrapper.download(
        `${BASE_API}/api/business/${getActiveBusinessId()}/contacts/export-csv/`,
        "",
        "contacts.csv"
    );
};
export const deleteContact = async (
    contact_id: any,
    business_id: string | number
): Promise<ResponseData> => {
    return await fetchWrapper.delete(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`
    );
};

export const bulkDelete = async (
    business_id: string | number,
    contact_ids: Array<number>
) => {
    return await fetchWrapper.post(
        `${BASE_API}/api/business/${business_id}/contacts/bulk-delete/`,
        { contact_ids: contact_ids }
    );
};
export const sendContactCsv = async (
    business_id: string | number,
    dataArray: object[]
) => {
    return await fetchWrapper.post(
        `${BASE_API}/api/business/${business_id}/contacts/bulk-create/`,
        { contacts: dataArray }
    );
};

export const getContactLogs = async (id: any, phonenumber: any) => {
    return await fetchWrapper.get(
        `${BASE_API}/api/telephony/calls/?number=${id}&phone_number=${phonenumber}&page_size=10`
    );
};
