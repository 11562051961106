import { inject, ref } from "vue";
import outgoing from "../assets/Images/icons/calltype/call-outgoing.svg";
// import unanswered from '../assets/Images/icons/calltype/unanswered.svg'
import incoming from "../assets/Images/icons/calltype/call-incoming.svg";
import transferred from "../assets/Images/icons/calltype/call-transferred.svg";
import missed from "../assets/Images/icons/calltype/call-missed.svg";
import voicemailcall from "../assets/Images/icons/calltype/call-outgoing.svg";
import internal from "../assets/Images/icons/calltype/call-outgoing.svg";
import dayjs from "dayjs";
import {
    ResponseData,
    downloadCallHistory,
    filterCallHistoryNew,
    exportCallHistory,
    getCallHistoryNew,
} from "./queries";
import {
    CallInfo,
    CallTypeFormatting,
    SortedData,
    allCallData,
} from "./types/call";
import logger from "./logger";
import {
    useCallStore,
    useContactStore,
    useDialerStore,
    useNumberStore,
} from "../stores";
import { Contacts } from "./types/contact";
import { notify } from "@kyvg/vue3-notification";
import { callNumber } from "./dialer";

const callList = ref<ResponseData | null>(null);
export function useCallLogs() {
    const callStore = useCallStore();
    const contactStore = useContactStore();
    const dialerStore = useDialerStore();
    const numberStore = useNumberStore();

    const getPressoneInstance: any = inject("getPressoneInstance");

    const isLoading = ref(false);
    const callList = ref<allCallData | null>(null);
    const avatarGradient = ref<string>(
        "linear-gradient(180deg, #FF8268 0%, rgba(255, 173, 156, 0.76) 100%)"
    );

    const callContact = async (number: string): Promise<void> => {
        // revert back from any
        if (!number || number.length < 9) {
            notify({
                text: "Please enter a valid phone number",
                type: "error",
            });
            return;
        }
        if (numberStore.subscriptionStatus === "past_due") {
            notify({
                text: "Your line is not connected. Account restricted due to outstanding payment",
                type: "error",
            });
            return;
        }
        if (
            !dialerStore.isRegistered[numberStore?.currentUserReceiverKey!]
                ?.isConnected
        ) {
            notify({
                text: "Your line is not connected. Please check your internet connection and try again",
                type: "error",
            });
            return;
        }
        if (
            number.startsWith("+234") &&
            numberStore.activeNumber?.business_number?.country_code.toLowerCase() ===
                "ng"
        ) {
            number = "0" + number.slice(4);
        } else if (
            number.startsWith("234") &&
            numberStore.activeNumber?.business_number?.country_code.toLowerCase() ===
                "ng"
        ) {
            number = "0" + number.slice(3);
        }
        dialerStore.showOutboundModal = false;
        try {
            try {
                callNumber(number, getPressoneInstance!);
            } catch (error) {
                notify({
                    text: "Cannot make call",
                    type: "error",
                });
            }
        } catch (error) {
            notify({
                text: "Enter a valid phone and start call",
                type: "error",
            });
        }
    };
    const cleanInput = () => {
        dialerStore.numberToCall = dialerStore.numberToCall.replace(/\s/g, "");
    };
    const getCallHistory = async (
        businessId?: string | number,
        source?: string
    ) => {
        isLoading.value = true;
        try {
            const getAllLogs = await listOfCallHistory(
                "10",
                "",
                undefined,
                true,
                businessId,
                source
            );
            callList.value = getAllLogs;
            const callLogs: CallInfo[] = callList.value?.data || [];
            callStore.pageIndex = callList.value?.page_index;
            callStore.selectedCallInfo = callLogs[0];
            isLoading.value = false;
            console.log(callList.value);
        } catch (error) {
            isLoading.value = false;
        }
    };
    const getFilteredHistory = async (sortedData: SortedData) => {
        isLoading.value = true;
        try {
            const getFilteredLogs = await listOfFilteredCallHistory(sortedData);
            callList.value = getFilteredLogs;
            const callLogs = callList?.value?.data!;
            callStore.pageIndex = callList.value?.page_index;
            callStore.selectedCallInfo = callLogs[0];
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
        }
    };
    const selectRow = (
        selectedRow: CallInfo,
        gradient: string,
        index: number
    ) => {
        console.log("selected");

        avatarGradient.value = gradient;

        callStore.selectedCallInfo = selectedRow;
        callStore.selectedCallContactDetails =
            contactStore.savedContacts?.data?.find(
                (contact: Contacts) =>
                    contact.contact_name ===
                    callStore.selectedCallInfo?.caller_name
            ) ?? {};
        callStore.successSave = false;
        callStore.selectedIndex = index;
    };
    const navigate = async (link: string, index?: number) => {
        if (link) {
            isLoading.value = true;
            try {
                const getAllLogs = await listOfCallHistory("", link);
                callList.value = getAllLogs;

                // Check if results exist before accessing the first item
                if (callList.value?.data && callList.value?.data.length > 0) {
                    const callLogs = callList.value.data;
                    callStore.pageIndex = callList.value?.page_index;
                    callStore.selectedCallInfo = callLogs[index ? index : 0];

                    callStore.selectedCallContactDetails =
                        contactStore.savedContacts?.data?.find(
                            (contact: Contacts) =>
                                contact.contact_name ===
                                callStore.selectedCallInfo?.caller_name
                        ) ?? {};
                }
                isLoading.value = false;
                callStore.successSave = false;
            } catch (error) {
                isLoading.value = false;
                console.log(error);
            }
        } else {
            return notify({
                text: "No more records available on the next page.",
                type: "error",
            });
        }
    };
    const numberInputRules = [
        (v: string) => !!v || "Phone number is required",
        (v: string) => {
            const strippedNumber = v.replace(/\s/g, ""); // Remove spaces from the input
            return (
                /^\+?\d+$/.test(strippedNumber) ||
                "Invalid Phonenumber. Input must be a valid number (allowing +)"
            );
        },
    ];
    return {
        getFilteredHistory,
        getCallHistory,
        isLoading,
        callList,
        selectRow,
        avatarGradient,
        navigate,
        callContact,
        cleanInput,
        numberInputRules,
    };
}
export const listOfCallHistory = async (
    pageSize: string = "",
    link: string,
    agent_id?: number,
    showAllLogs?: boolean,
    businessId?: string | number,
    source?: string
) => {
    try {
        const requestLink = link ? link : "";
        const callListData = await getCallHistoryNew(
            pageSize,
            requestLink,
            agent_id,
            showAllLogs,
            businessId,
            source
        );
        callList.value = callListData;
        return callList.value?.data;
    } catch (error) {
        logger.error(error, "List Call History");
        return null;
    }
};
export const listOfFilteredCallHistory = async (filter: SortedData) => {
    try {
        callList.value = await filterCallHistoryNew(filter);
        return callList.value?.data;
    } catch (error) {
        logger.error(error, "List Filtered Call History");
        return null;
    }
};
export const downloadCallLogs = async (
    user_id: string,
    from_date: string,
    to_date: string
) => {
    try {
        const downloadedCSV = await downloadCallHistory(
            user_id,
            from_date,
            to_date
        );
        console.log(downloadedCSV);
    } catch (error) {
        logger.error(error, "Download Call History");
    }
};
export const exportCallLogs = async () => {
    try {
        const downloadedCSV = await exportCallHistory();
        console.log(downloadedCSV);
    } catch (error) {
        logger.error(error, "Export Call Log");
    }
};
export function determineCallStatus( // get call type and icon
    is_dialed: boolean,
    is_forwarded_call: boolean,
    is_missed_call: boolean,
    has_voice_mail: boolean,
    voicemail: string | null,
    recording: string | null
): CallTypeFormatting {
    let displayType: CallTypeFormatting = {
        icon: "",
        color: "",
        type: "",
        textColor: "",
        fontColor: "",
    };
    if (is_dialed) {
        displayType = {
            icon: outgoing,
            color: "#ebfff0",
            type: "Outgoing",
            textColor: "#E3EFFC",
            fontColor: "#34C759",
        };
    }
    // if (is_dialed && !recording?.length) {
    //   displayType = {
    //     icon: unanswered,
    //     color: "#F7F9FC",
    //     type: "Unanswered",
    //     textColor: "#1D2739",
    //   };
    // }
    else if (!is_dialed && recording?.length && !is_missed_call) {
        if (!is_forwarded_call)
            displayType = {
                icon: incoming,
                color: "#E3EFFC",
                type: "Incoming",
                textColor: "#E3EFFC",
                fontColor: "#3D89DF",
            };
        else
            displayType = {
                icon: transferred,
                color: "#ebfff0",
                type: "Forwarded",
                textColor: "#E3EFFC",
            };
    } else if (!is_dialed && is_missed_call) {
        displayType = {
            icon: missed,
            color: "#FBEAE9",
            type: "Missed",
            textColor: "#E3EFFC",
            fontColor: "#FF3B30",
        };
    } else if (has_voice_mail) {
        displayType = {
            icon: voicemailcall,
            color: "#ebfff0",
            type: "Voicemail",
            textColor: "#E3EFFC",
        };
    } else if (is_dialed && has_voice_mail && voicemail) {
        displayType = {
            icon: internal,
            color: "#ebfff0",
            type: "Internal",
            textColor: "#E3EFFC",
        };
    }
    return displayType;
}

const getCallTypeFormatting = (callLog: CallInfo): CallTypeFormatting => {
    return determineCallStatus(
        callLog.is_dialed,
        callLog.is_forwarded_call,
        callLog.is_missed_call,
        callLog.has_voice_mail,
        callLog.voicemail,
        callLog.recording
    );
};
export const getCallStatusType = (callLog: CallInfo | null): string => {
    const { type } = getCallTypeFormatting(callLog!);
    return type;
};

export const getCallStatusIcon = (callLog: CallInfo | null): string => {
    const { icon } = getCallTypeFormatting(callLog!);
    return icon;
};

export const getCallStatusBackgroundColor = (callLog: CallInfo): string => {
    const { color } = getCallTypeFormatting(callLog);
    return color;
};

export const getCallStatusTextColor = (callLog: CallInfo): string => {
    const { color } = getCallTypeFormatting(callLog);
    return color;
};
export const downloadAudio = (audioUrl: string) => {
    fetch(audioUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Recordings.wav";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
};

export const onNextPage = async (response: any) => {
    try {
        await listOfCallHistory("", response.next);
    } catch (error) {
        logger.error(error, "Next Call Log Page Pagination");
    }
};
export const onPrevPage = async (response: any) => {
    try {
        await listOfCallHistory("", response.previous);
    } catch (error) {
        //
        logger.error(error, "Previous Call Log Page Pagination");
    }
};
export const getMaxLimit = (value: number) => {
    let maxLimit = 0;
    switch (true) {
        case value >= 100 && value < 200:
            maxLimit = 100;
            break;
        case value >= 50 && value < 100:
            maxLimit = 50;
            break;
        case value >= 25 && value < 50:
            maxLimit = 25;
            break;
        default:
            maxLimit = 10;
    }
    return maxLimit;
};

export function getDateRange(period: string) {
    const today = dayjs();
    let fromDate, toDate;
    switch (period) {
        case "past_week":
            fromDate = today.subtract(6, "day").format("YYYY-MM-DD");
            break;
        case "past_month":
            fromDate = today
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            break;
        case "past_year":
            fromDate = today
                .subtract(1, "year")
                .startOf("year")
                .format("YYYY-MM-DD");
            break;
        default:
            throw new Error("Invalid period provided");
    }

    toDate = today.format("YYYY-MM-DD");
    console.log(fromDate, toDate);
    return { from_date: fromDate, to_date: toDate };
}
