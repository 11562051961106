import { useAuthStore } from "../stores/index";
import { BASE_API, AUTH_EXCLUDE_LIST } from "./constants";
import { getGeolocation, hashTimezone } from "./integrations/geofencing";

// Define custom error classes
class AuthError extends Error {}
// class PermissionError extends Error {}

// Define the type for the response data
interface ResponseData {
    [key: string]: any;
}

export const fetchWrapper = {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    patch: request("PATCH"),
    download: downloadRequest,
    public_post: publicRequest("POST"),
    public_get: publicRequest("GET"),
    formDataPost: formDataRequest("POST"),
};

function formDataRequest(method: string) {
    return async (url: string, formData: FormData) => {
        const requestOptions: RequestInit = {
            method: method,
            body: formData,
            headers: await authHeader(url),
        };

        return fetch(url, requestOptions).then(handleResponse);
    };
}

function request(method: string) {
    return async (
        url: string,
        body?: any, // body can be undefined
        {
            credentials,
            geofence,
        }: { credentials?: string; geofence?: boolean } = {}
    ) => {
        const requestOptions: RequestInit = {
            method: method,
            headers: await authHeader(url),
            // headers: { Accept: "application/json" },
        };
        if (body) {
            if (requestOptions.headers instanceof Headers) {
                requestOptions.headers.set("Content-Type", "application/json");
            }
            requestOptions.body = JSON.stringify(body);
        }

        if (credentials) {
            requestOptions.credentials = credentials as RequestCredentials; // Type assertion
        }
        if (geofence) {
            const geoLocation = await getGeolocation();

            if (requestOptions.headers instanceof Headers) {
                if (geoLocation) {
                    requestOptions.headers?.set("X-GHSH", geoLocation); // only send if geoLocation is allowed
                }
                requestOptions.headers?.set("X-TZ", hashTimezone()); // sends hashed TimeZone
            }
        }
        return fetch(url, requestOptions).then(handleResponse);
    };
}

function publicRequest(method: string) {
    return (url: string, body?: any) => {
        const requestOptions: RequestInit = {
            method: method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        if (body) {
            if (requestOptions.headers instanceof Headers) {
                requestOptions.headers.set("Content-Type", "application/json");
            }
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    };
}

async function downloadRequest(url: string, body: any, filename: string) {
    const requestOptions: RequestInit = {
        method: "GET",
        headers: await authHeader(url),
    };

    if (body) {
        if (requestOptions.headers instanceof Headers) {
            requestOptions.headers.set("Content-Type", "application/json");
        }
        requestOptions.body = JSON.stringify(body);
    }

    return fetch(url, requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob], { type: "text/csv" })
            );
            const a = document.createElement("a");
            a.href = url;
            a.setAttribute("download", filename);
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
}

// Helper functions

async function authHeader(url: string) {
    try {
        const authStore = useAuthStore();
        const { token } = authStore;
        const needsAuth = !AUTH_EXCLUDE_LIST.includes(new URL(url).pathname);
        if (needsAuth) {
            // Check if the token is expired
            const isExpired = isTokenExpired(token);
            if (isExpired) {
                try {
                    // Attempt to refresh the token

                    const { data } = await fetchWrapper.post(
                        `${BASE_API}/api/auth/token/refresh/`,
                        null,
                        {
                            credentials: "include",
                        }
                    );
                    authStore.token = data!.token;
                } catch (err) {
                    console.warn(err, "Unable to refresh expired token.");
                    authStore.logout();

                    throw new AuthError();
                }

                return {
                    Authorization: `Bearer ${authStore.token}`,
                };
            }

            const headers = new Headers();
            headers.set("Authorization", `Bearer ${authStore.token}`);

            return headers;
        } else {
            return undefined;
        }
    } catch (error) {
        console.log(error);
    }
}

async function handleResponse(response: Response) {
    if (response.status === 204) {
        return {
            data: null, // No data for 204 No Content response.
            status: response.status,
        };
    }
    const isJson = response.headers
        ?.get("content-type")
        ?.includes("application/json");
    const data = isJson ? ((await response.json()) as ResponseData) : null;

    if (!response.ok) {
        // if (response.status === 401) {
        //   throw new AuthError();
        // }

        // if (response.status === 403) {
        //   throw new PermissionError();
        // }

        //check if response is a json and handle accordingly
        const error = data
            ? data.message
                ? data.message
                : JSON.stringify(data)
            : response.status;
        return Promise.reject(error);
    }

    return {
        data,
        status: response.status,
    };
}

const decodeToken = (token: string) => {
    try {
        if (token.split(".").length !== 3 || typeof token !== "string") {
            return null;
        }

        const payload = token.split(".")[1];

        const base64 = payload.replace("-", "+").replace("_", "/");
        const decoded = JSON.parse(atob(base64));

        return decoded;
    } catch (error) {
        return null;
    }
};

const isTokenExpired = (token: string | null) => {
    const decodedToken = decodeToken(token as string);
    if (decodedToken && decodedToken.exp) {
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        const now = new Date();
        now.setSeconds(now.getSeconds() + 10);
        return expirationDate.valueOf() < now.valueOf();
    } else {
        return true;
    }
};
