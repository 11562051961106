import { defineStore } from "pinia";
import { PINIA_PERSIST_OPTIONS } from "../helpers";
import { useNumberStore, useUsersStore } from ".";
import { notify } from "@kyvg/vue3-notification";
import router from "../router";

export interface BusinessList {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    business: {
        balance: number | string;
        name: string;
        business_timezone: string;
        id: number;
        verified?: true;
    };
    user: number;
    business_name: string;
    date_created: string;
}
interface BusinessState {
    businesses: BusinessList[] | null; // replace object later
    activeBusiness: BusinessList | null;
}
export const useBusinessStore = defineStore({
    id: "business",
    state: (): BusinessState => ({
        businesses: null, // list all business under this agent
        activeBusiness: null,
    }),
    getters: {
        currentBusinessId(): number | string {
            // current business id.
            return this.activeBusiness ? this.activeBusiness.business.id : "";
        },
        activeProfile(): any {
            // return current business after update. filter the businesslist based on the id of active business since the businesslist is more reactive. This fixes the issue of profile not updateing immediately
            return this.businesses?.find(
                (business) =>
                    business?.business.id === this.activeBusiness?.business.id
            );
        },
        currentUserId(): number | string {
            return this.activeBusiness ? this.activeBusiness.user : "";
        },
        getInactiveBusinesses: (state) =>
            state.businesses?.filter(
                (business: BusinessList) =>
                    business.business?.id != state.activeBusiness?.business?.id
            ),
    },
    actions: {
        getBusiness() {
            // retrieve the list of businesses
            const userStore = useUsersStore();
            this.businesses = userStore.userProfile;
            if (this.businesses?.length && !this.activeBusiness) {
                // sets the first element in the array to the active number if no active number exists
                this.activeBusiness = this.businesses[0];
            }
        },
        async setActiveBusiness(
            business: BusinessList,
            refresh: boolean = true
        ) {
            const numberStore = useNumberStore();
            const userStore = useUsersStore();

            numberStore.activeNumber = null;
            numberStore.activeBusinessNumber = null;
            this.activeBusiness = null;
            this.activeBusiness = business;
            userStore.user_role = this.activeBusiness.role;
            notify({
                type: "success",
                duration: 1500,
                title: `Business Transition Successful`,
                text: `Your primary business has successfully transitioned to ${business?.business?.name}.`,
            });
            if (refresh) {
                if (window.location.href.includes("add-new-call-flow")) {
        
                  router.push("/managers/incoming-flow");
        
                  setTimeout(() => {
                    router.go(0);
                  }, 100);
        
                } else {
                    
                  router.go(0);
                }
            }
        },
        async refreshActiveBusiness() {
            // when a new business is bought
            const userStore = useUsersStore();
            let business = userStore.userProfile[0];

            await this.setActiveBusiness(business, false);
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
