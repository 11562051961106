<template>
  <div class="container">
    <div class="position-relative">
      <img
        src="../../assets/Images/close.svg"
        class="position-absolute close-feedback pointer"
        @click="userStore.showFeedBackModal = false"
      />
      <img
        class="image"
        src="../../assets/Images/icons/feedback.png"
        alt="Image"
      />
      <div class="content px-7 pb-5 mt-6">
        <p class="feedback-title">
          We have made some recent improvements to our web experience with the
          new design ✨
        </p>
        <p class="feedback-subtitle">
          Tell us about your experience to help us improve the new web
          experience.
        </p>
        <p
          class="feedback-share text-primary mt-3 pointer"
          @click="showFeedBackModal = true"
        >
          Share your thoughts <v-icon size="18">mdi-arrow-right</v-icon>
        </p>
      </div>
    </div>
    <v-dialog
      v-model="showFeedBackModal"
      max-width="500"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card flat class="modal-card grey-dark py-6 px-5">
        <div class="d-flex justify-space-between align-center">
          <div class="step-indicator d-flex align-center pt-2">
            <div
              v-for="step in 4"
              :key="step"
              class="step-dot"
              :class="{ 'step-dot-black': step === currentQuestionIndex + 1 }"
            ></div>
          </div>
          <img
            src="../../assets/Images/close.svg"
            class="pointer"
            @click="showFeedBackModal = false"
          />
        </div>

        <template v-if="currentQuestionIndex < questions.length">
          <p class="question-text mt-5">
            {{ questions[currentQuestionIndex].text }}
          </p>
          <div v-if="currentQuestionIndex !== 3">
            <div class="options mt-3 d-flex ga-2">
              <v-card
                v-for="(option, i) in questions[currentQuestionIndex].options"
                :key="i"
                class="yes-no-card mt-3 pointer align-center"
                elevation="0"
                :class="{
                  'selected-card': selectedOptions[currentQuestionIndex] === i,
                }"
                @click="setOptions(currentQuestionIndex, i)"
                width="82"
              >
                <div
                  class="d-flex justify-space-between align-center px-1 inner-card"
                  :class="{
                    'selected-inner-card':
                      selectedOptions[currentQuestionIndex] === i,
                  }"
                >
                  <p class="template-text selected-business-name">
                    {{ option }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="radio-button"
                    :class="{
                      'radio-button-selected':
                        selectedOptions[currentQuestionIndex] === i,
                    }"
                  ></div>
                </div>
              </v-card>
            </div>
            <small class="text-red-accent-3" v-if="showErrorMessage"
              >Please selected an option</small
            >
          </div>

          <div v-else class="mt-6 mb-3">
            <p class="experience-rate mb-3">
              How would you rate your experience?
            </p>
            <div class="d-flex ga-3 align-center">
              <div
                v-for="(emoji, i) in questions[currentQuestionIndex].emojis"
                :key="i"
                @click="setOptions(currentQuestionIndex, i)"
                @mouseleave="unhoverEmoji"
                class="emoji-container-div d-flex align-center"
              >
                <img
                  :src="getEmojiSrc(i)"
                  @click="toggleEmoji(emoji)"
                  @mouseover="hoverEmoji(emoji)"
                  :class="{
                    'selected-emoji': selectedEmoji?.name === emoji.name,
                    'hovered-emoji': hoveredEmoji?.name === emoji.name,
                  }"
                  class="emoji-image pointer"
                  width="35"
                />
                <v-tooltip activator="parent" location="bottom" height="28"
                  >{{ emoji.name }}
                </v-tooltip>
              </div>
              <p
                class="rate-value px-3 rounded-lg"
                :style="{ color: selectedEmoji?.color }"
              >
                {{ selectedEmoji?.name }}
              </p>
            </div>
          </div>
          <div class="mt-6">
            <p class="reason-text">
              {{ questions[currentQuestionIndex].reasonText }}
            </p>
            <v-textarea
              v-model="reasons[currentQuestionIndex]"
              variant="outlined"
              no-resize
              rows="2"
              class="mt-2"
            ></v-textarea>
          </div>
          <div class="d-flex justify-end ga-2 mt-8">
            <v-btn
              v-if="currentQuestionIndex > 0"
              @click="previousQuestion"
              flat
              class="outline-primary-color"
              height="40"
              >Back</v-btn
            >
            <v-btn
              v-if="currentQuestionIndex < 3"
              @click="nextQuestion"
              class="filled-primary-color"
              height="40"
              flat
              >Next</v-btn
            >
            <v-btn
              v-else
              @click="submitFeedback"
              class="filled-primary-color"
              height="40"
              :loading="isLoading"
              flat
              >Submit</v-btn
            >
          </div>
        </template>
        <template v-else>
          <p class="submit-text">
            Please review your feedback before submitting.
          </p>
          <div class="d-flex justify-end mt-8"></div>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSuccessModal"
      max-width="390"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card flat class="modal-card grey-dark py-6 px-5">
        <div class="d-flex justify-center">
          <img
            src="../../assets/Manager/personalization/success-audio-emoji.svg"
            alt=""
            width="70"
          />
        </div>
        <div class="text-center mt-4">
          <p class="success-title">Thank you for providing your feedback!</p>
          <p class="uccess-subtitle mt-4">
            We appreciate your input and strive to continuously improve our
            services based on your experience. We have also sent a summary of
            this call to your email.
          </p>
        </div>
        <v-btn
          @click="closeFeedBackPage"
          class="filled-primary-color mt-9 mx-auto"
          flat
          height="40"
          width="130"
          >Close this page</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useUsersStore } from "../../stores/users.store";
import {
  AMPLITUDE_EVENTS,
  trackAmplitudeEvent,
  updateFeedbackSheet,
  sendCallFeedback,
} from "../../helpers";

const userStore = useUsersStore();

const showFeedBackModal = ref(false);
const showSuccessModal = ref(false);
const currentQuestionIndex = ref(0);
const selectedOptions = ref<number[]>([]);
const selectedEmoji = ref<{
  name: string;
  color: string;
} | null>(null); // Assuming there are 4 emojis for the fourth question
const reasons = ref<string[]>([]);
const showErrorMessage = ref<boolean>(false);
const currentStep = ref(0);
const questions = ref([
  {
    text: "Can you make & receive calls successfully?",
    showReason: true,
    options: ["Yes", "No"],
    reasonText: "If no, share your reason",
  },
  {
    text: "Did you encounter any challenges when setting up your incoming call flow?",
    showReason: true,
    reasonText: "If yes, share your experience",
    options: ["Yes", "No"],
  },
  {
    text: "Do you encounter any difficulties when inviting your team members?",
    reasonText: "If your answer is yes, share your experience",
    showReason: true,
    options: ["Yes", "No"],
  },

  {
    text: "How do you feel about our service?",
    reasonText: "Tell us more. What is working well? What can be improved?",
    emojis: [
      {
        src: "/assets/Images/emojis/very-dissatisfied.png",
        name: "Very dissatisfied!",
        reasonText: "If yes, share your experience",
        color: "#FF3B30",
      },
      {
        src: "/assets/Images/emojis/dissatisfied.png",
        name: "Dissatisfied",
        color: "#3D89DF",
      },
      {
        src: "/assets/Images/emojis/satisfied.png",
        name: "Satisfied",
        color: "#E7AE05",
      },
      {
        src: "/assets/Images/emojis/very-satisfied.png",
        name: "Very satisfied!",
        color: "#40B869",
      },
    ],
    showReason: false,
  },
]);
const isCurrentQuestionComplete = computed(() => {
  const currentOptions = selectedOptions.value[currentQuestionIndex.value];
  const currentEmoji = selectedEmoji.value;
  return currentOptions !== undefined || currentEmoji !== null;
});
const nextQuestion = () => {
  if (isCurrentQuestionComplete.value) {
    if (showErrorMessage.value) showErrorMessage.value = false;

    if (currentQuestionIndex.value < questions.value.length - 1) {
      currentQuestionIndex.value++;
      currentStep.value++;
    }
  } else {
    showErrorMessage.value = true;
  }
};

const previousQuestion = () => {
  if (currentQuestionIndex.value > 0) {
    currentQuestionIndex.value--;
  }
};

const setOptions = (questionIndex: number, optionIndex: number) => {
  selectedOptions.value[questionIndex] = optionIndex;
};

const toggleEmoji = (emoji: { name: string; color: string }) => {
  selectedEmoji.value = emoji;
};

const getEmojiSrc = (index: number) => {
  const currentQuestion = questions.value[currentQuestionIndex.value];
  const currentEmoji = selectedEmoji.value;
  const hoverEmoji = hoveredEmoji.value;
  if (currentEmoji?.name === currentQuestion?.emojis![index].name) {
    return `${currentQuestion?.emojis![index].src.replace(
      ".png",
      "-active.png"
    )}`;
  }
  if (hoverEmoji?.name === currentQuestion?.emojis![index].name) {
    return `${currentQuestion?.emojis![index].src.replace(
      ".png",
      "-active.png"
    )}`;
  } else {
    return `${currentQuestion?.emojis![index].src}`;
  }
};
const hoveredEmoji = ref<any>(null);
const hoverEmoji = (emoji: any) => {
  // Set the active emoji index when hovering
  hoveredEmoji.value = emoji;
};

const unhoverEmoji = () => {
  // Clear the active emoji index when unhovering
  hoveredEmoji.value = null;
};

const isLoading = ref<boolean>(false);

const submitFeedback = async () => {
  isLoading.value = true;
  const currentDate = new Date();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and year from the current date
  const day = currentDate.getDate();
  const monthIndex = currentDate.getMonth();
  const year = currentDate.getFullYear();

  // Format the date
  const formattedDate = `${day.toString().padStart(2, "0")} ${
    monthNames[monthIndex]
  }, ${year}`;

  try {
    const customer_feedback = selectedOptions.value.map(
      (option: any, index: number) => ({
        answer: option === 0 ? "Yes" : "No",
        question: questions.value[index].text,
        comment: reasons.value[index],
      })
    );
    const overallFeedback = selectedEmoji.value
      ? {
          answer: selectedEmoji.value?.name,
          comment: reasons.value![currentQuestionIndex.value], // Assuming the emoji reason is the same as the current question's reason
          question: "How do you feel about our service?",
        }
      : null;

    if (overallFeedback) {
      // Find and replace the existing "How do you feel about our service?" feedback with the combined emoji feedback
      const index = customer_feedback.findIndex(
        (item: any) => item?.question === "How do you feel about our service?"
      );
      if (index !== -1) {
        customer_feedback?.splice(index, 1, overallFeedback);
      } else {
        customer_feedback?.push(overallFeedback);
      }
    }

    const convertedFeedback = {
      DATE: formattedDate,
      EMAIL: userStore.currentUserBusinessLevel?.personal_email,
      CALL_FBK_ANSWER: customer_feedback[0].answer === "No" ? "No" : "Yes",
      CALL_FBK_COMMENT: customer_feedback[0].comment,
      CALLFLOW_FBK_ANSWER: customer_feedback[1].answer === "No" ? "No" : "Yes",
      CALLFLOW_FBK_COMMENT: customer_feedback[1].comment,
      TEAM_MEMBER_FBK_ANSWER:
        customer_feedback[2].answer === "No" ? "No" : "Yes",
      TEAM_MEMBER_FBK_COMMENT: customer_feedback[2].comment,
      OVERALL_FBK_ANSWER: customer_feedback[3].answer.replace(
        "Very satisfied!",
        "Satisfied"
      ),
      OVERALL_FBK_COMMENT: customer_feedback[3].comment,
    };

    await sendCallFeedback(convertedFeedback);
    trackAmplitudeEvent(AMPLITUDE_EVENTS.NEW_EXPERIENCE_FEEBACK_SUBMITTED, {
      overall_fbk: customer_feedback[3],
    });
    await updateFeedbackSheet();
  } catch (error) {
    console.log(error);

    showFeedBackModal.value = false;
  } finally {
    showSuccessModal.value = true;
    showFeedBackModal.value = false;
    isLoading.value = false;
  }
};
const closeFeedBackPage = () => {
  showSuccessModal.value = false;
  showFeedBackModal.value = false;
  userStore.showFeedBackModal = false;

  if (userStore.currentUserBusinessLevel)
    userStore.currentUserBusinessLevel.has_completed_feedback = true; // update store without refreshing
};
</script>
<style scoped>
.container {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  width: 400px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow effect */
}

.image {
  width: 100%;
  height: 200px;
}

.content {
  width: 100%;
}
.feedback-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 26.1px;
  letter-spacing: -0.02em;
  color: #1d2739;
}
.feedback-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #475367;
}
.feedback-share {
  font-size: 15px;
  font-weight: 600;
  line-height: 25.2px;
}
.question-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 33.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1d2739;
}
.yes-no-card {
  border: 1px solid #d0d5dd;
  border-radius: 200px !important;
  padding: 3px;
}
.selected-card {
  background: linear-gradient(0deg, #edfaff, #edfaff);
  border: none;
}
.selected-inner-card {
  border: 1px solid #21baf1;
  border-radius: 300px;
  width: 75px;
}
.reason-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: left;
  color: #101928;
}
.experience-rate {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: left;
  color: #475367;
}
.rate-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: left;
  background: #f7f9fc;
}
.close-feedback {
  top: 10px;
  right: 10px;
}
.success-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: center;
  color: #344054;
}
.success-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: center;
  color: #667185;
}

.emoji-container-div {
  width: 44px;
  height: 44px;
}
.emoji-image:hover {
  width: 39px;
}
</style>