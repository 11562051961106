import {
    ActiveCallNote,
    CallNoteCreateFromLogs,
    CallNoteUpdateFromLogs,
} from "./../helpers/types/notes";
import { defineStore } from "pinia";
import { addNewCallNote, updateCallNote } from "../helpers";
import logger from "../helpers/logger";

interface NoteState {
    activeCallNote: ActiveCallNote | any | null;
}

export const useNotesStore = defineStore({
    id: "note",
    state: (): NoteState => ({
        activeCallNote: null,
    }),
    getters: {},
    actions: {
        async saveCallNote(
            note: string,
            noteObject:
                | CallNoteCreateFromLogs
                | CallNoteUpdateFromLogs
                | ActiveCallNote
                | any,
            idForUpdate: number | undefined = undefined
        ) {
            noteObject.note = note;
            noteObject = {
                ...noteObject,
                ...(idForUpdate ? { id: idForUpdate } : {}),
            };
            try {
                if (idForUpdate) {
                    const response = await updateCallNote(
                        {
                            note: noteObject.note,
                        },
                        idForUpdate
                    );
                    return response.data;
                } else {
                    const createdNote = await addNewCallNote(
                        noteObject,
                        !!noteObject.call_log_id
                    );
                    return createdNote.data;
                }
            } catch (e) {
                logger.error(e, "note store");
                throw e;
            }
        },
    },
});
